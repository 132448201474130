/* Transformer les pixels en rem */


@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px : ();
  $rem : ();

  @each $value in $values {

    @if $value == 0 or $value == auto {
      $px : append($px, $value);
      $rem : append($rem, $value);
    } @else {
      $unit : unit($value);
      $val : parseInt($value);
      $px : append($px, $value);
      $rem : append($rem, ($val / $font-html-desktop-rem + rem));
    }
  }

  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}

// Génération d'un nouveau breakpoint

// Generate the extra extra small columns (480px)
@mixin make-xxs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xxs-column-offset($columns) {
  margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xxs-column-push($columns) {
  left: percentage(($columns / $grid-columns));
}
@mixin make-xxs-column-pull($columns) {
  right: percentage(($columns / $grid-columns));
}

// Remplacement de float par des inline-block
@mixin make-grid-columns-override($i: 1, $list: ".col-xxs-#{$i}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xxs-#{$i}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
  }
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns-override($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    display: inline-block;
    float: none;
    text-align: center;
  }
}


@mixin calc-grid-column-override($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index == 0) {
    .col-#{$class}-push-0 {
      left: auto;
    }
  }
  @if ($type == pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type == pull) and ($index == 0) {
    .col-#{$class}-pull-0 {
      right: auto;
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns-override($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}


// Create grid for specific class
@mixin make-grid-override($class) {
  @include float-grid-columns-override($class);
  @include loop-grid-columns-override($grid-columns, $class, width);
  @include loop-grid-columns-override($grid-columns, $class, pull);
  @include loop-grid-columns-override($grid-columns, $class, push);
  @include loop-grid-columns-override($grid-columns, $class, offset);
}

// Grid system
//
// Generate semantic grid columns with these mixins.

// Generate the extra small columns
@mixin make-xs-column-override($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: none;
  display: inline-block;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}

// Generate the small columns
@mixin make-sm-column-override($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-sm-min) {
    float: none;
    display: inline-block;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column-override($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-md-min) {
    float: none;
    display: inline-block;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column-override($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-lg-min) {
    float: none;
    display: inline-block;
    width: percentage(($columns / $grid-columns));
  }
}

//L'effet de transition, avec sa durée
@mixin transition( $effect, $time){
  -ms-transition:  $effect $time;
  -moz-transition:  $effect $time;
  -webkit-transition:  $effect $time;
  -o-transition:  $effect $time;
   transition: $effect $time;
}

//L'effet de transition avec plusieur propriete
@mixin transitions( $effects... ){
  -ms-transition:  $effects ;
  -moz-transition:  $effects ;
  -webkit-transition:  $effects ;
  -o-transition:  $effects ;
   transition: $effects ;
}

//L'effet transform
@mixin transform( $effect...){
  -ms-transform: $effect;
  -moz-transform: $effect;
  -webkit-transform: $effect;
  -o-transform: $effect;
  transform: $effect;

}

// Placeholder
@mixin placeholder {
  &::-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
  &:-ms-input-placeholder { @content }
}

@mixin placeholder-style-bleu-clair {
  font-style: italic;
  color: $bleu_clair;
}

//Icones
@mixin icone( $url, $width, $height ){
  background-image: url( $url );
  width: $width;
  height: $height;
  background-size: contain;
  background-repeat: no-repeat;
}


//Cartouche roll over
@mixin cartouche( $size, $weight){
  @include rem( margin-top, 20px);
  text-align: center;
  a{
    display: inline-block;
    @if $size >= 14{
      padding: 12px 53px;
      letter-spacing: 1.68px;
    }@else{
      padding: 6px 33px;
    }
    border: thin solid $almost-noir;
    text-transform: uppercase;
    color: black;
    font-size: $size+px;
    font-weight: $weight;
    border-radius: 999px;
    font-family: "lft-etica", sans-serif;
    &:hover{
      background: $rouge;
      color: $blanc;
      border-color: transparent;
      text-decoration: none;
      @at-root .page-univers-preludes & {
        background: $preludes-500;
      }
    }
    @media screen and (max-width: $mobile){
      @if $size >= 14{
        padding: 8px 25px;
        letter-spacing: normal;
      }
    }
  }
}

/*
Bandeau de couleur de la largeur de l'ecran
*/
@mixin bandeau-full-width( $color ){
  position: relative;
  z-index: 0;
  &:before{
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    left: 50%;
    width: 100vw;
    @include transform( translatex(-50%) );
    background: $color;
    z-index: -1;
  }
}

/*
La mixin permettant de tweaker l'appearance des selects
*/
@mixin appearance( $value ){
  -webkit-appearance: $value;
  -moz-appearance: $value;
  -ms-progress-appearance: $value;
  appearance: $value;
}
