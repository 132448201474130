#navbar {
  height      : 165px;
  border      : none;
  padding-top : 20px;
  width       : 100vw;
  background  : transparent;
  z-index     : 100;
  @include transitions(height 0.25s, padding-top 0.25s, background 0.25s);
  @media screen and (max-width : $mobile) {
    padding-top : 10px;
    height      : 100px;
    text-align  : center;
  }
  .not-front & {
    background : $blanc;
  }
  .#{$univ-imaginaire} &,
  .#{$univ-polar} &,
  .#{$univ-litterature} &,
  .#{$univ-romance} &,
  .#{$home-preludes} &,
  .page-enseignant & {
    background : transparent;
  }
  &.scrolledNav {
    height      : 90px;
    padding-top : 10px;
    box-shadow  : 0 5px 5px rgba(0, 0, 0, 0.15);
    background  : $blanc !important;
    @media screen and (max-width : $mobile) {
      height : 75px;
    }
    .slogan {
      display : none;
    }
    .navbar-collapse {
      top : 90px;
    }
  }
  img {
    width : 60px;
    @include transition(all, 0.25s);
    @media screen and (max-width : $mobile) {
      height        : auto;
      width         : 40px;
      margin-bottom : 10px;
    }
  }
  .navbar-header {
    left    : 0;
    display : inline-block;
    @media screen and (min-width : $mobile) {
      left : 50%;
    }
    .slogan {
      position    : absolute;
      font-size   : 16px;
      white-space : nowrap;
      padding-top : 5px;
      left        : 50%;
      color       : $blanc;
      @include transform(translatex(-50%));
      .#{$univ-litterature} &,
      .not-front & {
        color : $almost-noir;
      }
      .#{$univ-imaginaire} &,
      .#{$univ-polar} &,
      .#{$univ-romance} &,
      .page-enseignant & {
        color : $blanc;
      }
      @media screen and (max-width : $mobile) {
        font-size   : 13px;
        line-height : 13px;
        width       : 100px;
        bottom      : -10px;
        text-align  : center;
        left        : 0;
      }
    }
  }
  &.whiteBg {
    background : $blanc !important;
    .slogan {
      color : $gris-moyen;
    }
  }
  nav {
    @extend .container;
    display        : none;
    padding-top    : 50px;
    padding-bottom : 50px;
    @media screen and (max-width : $mobile) {
      padding : 0 0 45px;
    }
  }
  .caret {
    display : none;
  }
  .navbar-collapse {
    position   : absolute;
    top        : 165px;
    background : $blanc;
    left       : 0;
    width      : 100%;
    box-shadow : 0 5px 5px rgba(0, 0, 0, 0.15);
    @include transition(top, 0.25s);
    max-height : none;
    &.collapse {
      display : block;
    }
    @media screen and (max-width : $mobile) {
      margin  : 0;
      padding : 0;
      top     : 110px;
    }
  }
  .navbar-toggle {
    display : none;
  }
  &.menu-mobile {
    overflow-y : scroll;
    overflow-x : hidden;
    height     : 100vh;
  }
}

#page-header {
  .region-header {
    position : relative;
    height   : 0;
  }
  @media screen and (max-width : $mobile) {
    position : absolute;
    top      : 20px;
    width    : 100%;
    left     : 0;
  }
}
