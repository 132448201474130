.node {
  &.node-hw-livre.view-mode-teaser,
  &.node-hw-intervenant.view-mode-teaser,
  &.node-coup-coeur-libraire.view-mode-teaser_home,
  &.node-coup-coeur-libraire.view-mode-teaser,
  &.node-article.view-mode-teaser_home,
  &.node-article.view-mode-teaser,
  &.node-agenda.view-mode-teaser_home,
  &.node-agenda.view-mode-teaser,
  &.node-jeux.view-mode-teaser_home,
  &.node-jeux.view-mode-teaser,
  &.node-selection-thematique.view-mode-teaser_home,
  &.node-selection-thematique.view-mode-teaser,
  &.node-coup-coeur-libraire.view-mode-teaser,
  &.node-video.view-mode-teaser,
  &.view-mode-recherche .ds-bottom {
    @extend .shadow-transition;
  }
  &.view-mode-full {
    max-width : 950px;
    padding   : 0 15px;
    margin    : 0 auto;
    font-size : 0;
  }
  &.view-mode-recherche,
  &.node-hw-livre.view-mode-teaser_livre {
    margin     : 50px auto;
    background : transparent;
    max-width  : 920px;
    .row > div {
      vertical-align : middle;
    }
    @media screen and (max-width : $mobile) {
      margin : 15px 0;
    }
  }
  &.node-hw-livre.view-mode-teaser_livre {
    background : $blanc;
  }
}

.node-video {
  &.view-mode-teaser_home {
    margin-bottom : 30px;
    position      : relative;
    overflow      : hidden;
    cursor        : pointer;
    &:before {
      content  : "";
      position : absolute;
      @include icone('../images/icones/play.png', 59px, 59px);
      z-index  : 1;
      left     : calc(50% - 30px);
      top      : 50px;
    }
  }
  &.view-mode-recherche {
    background : transparent;
    &:hover {
      box-shadow : none !important;
      .field-name-node-link a {
        color : $rouge;
        &:after {
          background-image : url('../images/icones/arrow-link-hover.png');
        }
      }
    }
  }
  &.view-mode-teaser_livre {
    display  : inline-block;
    overflow : hidden;
    margin   : 25px 0;
    &:hover {
      box-shadow : none !important;
    }
    .col-sm-12 {
      position : relative;
    }
  }
}

.node-article,
.node-coup-coeur-libraire,
.node-agenda,
.node-selection-thematique,
.node-jeux {
  &.view-mode-teaser_home {
    background : $blanc;
    padding    : 25px 10px;
    min-height : 339px;
    cursor     : pointer;
    @media screen and (max-width : $mobile) {
      padding    : 15px 0;
      min-height : 0;
    }
    .row {
      margin : 0;
    }
  }
}

.node-agenda,
.node-selection-thematique,
.node-jeux {
  &.view-mode-teaser_home {
    min-height : 329px;
    @media screen and (max-width : $mobile) {
      min-height : 1px;
    }
  }
}

.node-article,
.node-coup-coeur-libraire {
  &.view-mode-teaser_home {
    .ds-left,
    .ds-right {
      float      : left;
      min-height : 240px;
      @media screen and (max-width : $mobile) {
        min-height : 0;
      }
    }
    .ds-left {
      @media screen and (max-width : $mobile) {
        width : 50%;
      }
    }
  }
}

.node-promo {
  &.view-mode-teaser {
    min-height : 330px;
    display    : inline-block;
    padding    : 0;
  }
}

.node-hw-livre {
  &.view-mode-teaser_livre {
    margin     : 50px 0;
    padding    : 15px;
    background : $blanc;
    .col-sm-2,
    .col-sm-10 {
      vertical-align : middle;
    }
  }
}

.node-article,
.node-agenda,
.node-jeux,
.node-coup-coeur-libraire,
.node-selection-thematique,
.node-hw-livre,
.vocabulary-hw-themes,
.node-video,
.node-hw-intervenant,
.node-actualite-enseignant {
  &.view-mode-teaser,
  &.view-mode-accroche {
    margin     : 50px 0;
    padding    : 15px;
    background : $blanc;
    @media screen and (max-width : $mobile) {
      margin : 30px 0;
    }
    .col-sm-2,
    .col-sm-10 {
      vertical-align : middle;
    }
    .node-hw-livre.view-mode-full & {
      margin : 20px 0;
    }
    .view-dossiers-presse & {
      margin : 0;
      .col-sm-10 {
        text-align : left;
      }
    }
  }
  &.view-mode-teaser {
    .view-display-id-page & {
      @include rem(padding, 25px);
    }
  }
  &.view-mode-recherche {
    .ds-bottom {
      background : $blanc;
      display    : inline-block;
      width      : 100%;
      padding    : 25px;
      @extend .shadow-transition;
      font-size  : 0;
      @media screen and (max-width : $mobile) {
        padding : 15px;
      }

      .ds-left {
        padding : 0;
      }
      .ds-left,
      .ds-right {
        //float          : left;
        vertical-align : middle;
        //display        : table-cell;
        @media screen and (max-width : $mobile) {
          padding : 0;
          float   : left;
        }
      }
      .ds-right {
        @media screen and (width : $mobile) {
          padding-left : 15px;
        }
      }
    }
  }
  &.view-mode-livre_selection_themat {
    background : #ffffff;
    padding    : 25px;
    margin     : 25px 0;
  }
}

.node-agenda.view-mode-recherche {
  .ds-left {
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
  }
}

.node-coup-coeur-libraire {
  &.view-mode-teaser_home,
  &.view-mode-teaser,
  &.view-mode-teaser_livre {
    background : $turquoise;
    div, p, h2, a {
      color : $blanc;
    }
  }
  &.view-mode-recherche {
    .ds-bottom {
      background : $turquoise;
      div, p, h2, a {
        color : $blanc;
      }
    }
  }
  &.view-mode-teaser_livre {
    padding : 50px 40px;

    .node-hw-livre.view-mode-full & {
      padding-top    : 20px;
      padding-bottom : 40px;
    }
    &:hover {
      box-shadow : none !important;
    }
    @media screen and (max-width : $mobile) {
      padding : 10px;
    }
  }
  .field-name-bloc-coups-de-coeur-auteur & {
    margin : 0;
  }
}

.node-jeux {
  &.view-mode-teaser,
  &.view-mode-teaser_home {
    background : $jaune;

    .node-hw-intervenant.view-mode-full & {
      margin : 40px 0 0;
    }

    .node-hw-livre.view-mode-full & {
      padding-top    : 30px;
      padding-bottom : 30px;
    }
  }
  &.view-mode-recherche {
    .ds-bottom {
      background : $jaune;
    }
  }
}

.taxonomy-term.vocabulary-hw-themes,
.taxonomy-term.vocabulary-hw-serie,
.taxonomy-term.vocabulary-hw-collections {
  &.view-mode-accroche {
    @extend .shadow-transition;
    margin     : 50px 0;
    padding    : 15px;
    background : $blanc;
    .col-sm-2,
    .col-sm-10 {
      vertical-align : middle;
    }
  }
  &.view-mode-teaser_home {
    text-align : center;
    padding    : 10px;
  }
  &.view-mode-recherche {
    margin     : 50px auto;
    background : transparent;
    max-width  : 920px;
    position   : relative;
    @media screen and (max-width : $mobile) {
      margin : 15px 0;
    }
  }
}

.node-habillage-publicitaire {
  &.view-mode-teaser {
    overflow : hidden;
    div, a {
      font-size : 0;
    }
  }
}

.page-taxonomy {
  &.#{$univ-romance} {
    background : $romance;
  }
  &.#{$univ-polar} {
    background : $polar;
  }
  &.#{$univ-imaginaire} {
    background : $imaginaire;
  }
  &.#{$univ-litterature} {
    background : $litterature;
  }
}

.node-hw-intervenant {
  &.view-mode-listes {
    padding    : 15px;
    background : $blanc;
    @extend .shadow-transition;
  }
  &.view-mode-full {
    .ds-left,
    .ds-right {
      float : left;
    }
    .ds-bottom {
      margin-top : 50px;
    }
  }
}

.node-hw-livre,
.node-hw-intervenant {
  &.view-mode-full {
    max-width : 1110px;
    position  : relative;
    .ds-left,
    .ds-right {
      float      : left;
      text-align : left;
      @media screen and (max-width : $mobile) {
        float      : none;
        text-align : center;
        padding    : 0;
      }
    }
    .ds-bottom {
      //float      : none;
      //clear      : both;
      margin-top : 50px;
      padding    : 0;
      @media screen and (max-width : $mobile) {
        margin : 30px 0 0;
      }
    }
    .gray-bg {
      position   : absolute;
      background : #f1f0f0;
      width      : 140vw;
      transform  : translate(-50%);
      z-index    : -1;
      @media screen and (max-width : $mobile) {
        left      : -15px;
        transform : none;
        width     : calc(100% + 30px);
      }
    }
  }
}

.node-hw-livre {
  .ds-top {
    display : none;
  }
  &.view-mode-full {
    .ds-bottom {
      @media screen and (max-width : $mobile) {
        margin : 30px -15px 0;
      }
    }
  }
  &.view-mode-recherche {
    .ds-top {
      display : block;
    }
  }
}

.node-hw-livre.view-mode-liste_slick {
  .slick-slide & {
    padding : 0 10px;
  }
}

.node-article,
.node-agenda {
  &.view-mode-teaser {
    .node-hw-livre.view-mode-full & {
      background : $gris-clair;
      margin     : 40px 0 0;
    }
  }
}

.node-article,
.node-agenda {
  &.view-mode-teaser {
    .node-hw-intervenant.view-mode-full & {
      margin : 40px 0 0;
    }
  }
}

.node-promo-en-cours {
  .node-hw-livre.view-mode-full & {
    &:hover {
      box-shadow : none !important;
    }
  }
}

.comment.view-mode-teaser_home {
  width         : 33.3333%;
  float         : left;
  padding-right : 30px;
  @media screen and (max-width : $mobile) {
    width   : 100%;
    padding : 0;
  }
  .col-sm-8 {
    text-align : left;
    padding    : 0;
  }
  .col-sm-4,
  .col-sm-8 {
    vertical-align : middle;
    @media screen and (max-width : $mobile) {
      padding : 0 15px;
    }
  }
}

.node-coup-coeur-libraire.view-mode-teaser {
  .node-hw-intervenant.view-mode-full & {
    &:hover {
      box-shadow : none !important;
    }
  }
}

.node-contenu-pedagogique {
  &.view-mode-block_teaser {
    @media screen and (max-width : $mobile) {
      margin-bottom : 15px;
    }
  }
}

.node-page.view-mode-full {
  max-width : 1260px;
}

.comment.view-mode-teaser_user {
  max-width     : 920px;
  margin        : 0 auto;
  padding       : 25px 0;
  border-bottom : thin solid $almost-blanc;
  .col-sm-3,
  .col-sm-9 {
    vertical-align : middle;
  }
  .col-sm-9 {
    text-align : left;
  }
}