html {
  font-size   : 14px;
  line-height : 1.1;

  @media screen and (max-width : $tab) {
    font-size : 14px;
  }
  @media screen and (max-width : $mobile) {
    font-size : 12px;
  }
}
