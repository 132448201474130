.bouton-menu {
  display    : inline-block;
  margin-top : 35px;
  cursor     : pointer;
  position   : absolute;
  left       : 60px;
  @include transition(all, 0.25s);
  @media screen and (max-width : $mobile) {
    left       : 15px;
    margin-top : 0;
  }
  .scrolledNav & {
    margin-top : 15px;
    @media screen and (max-width : $mobile) {
      margin-top : 0;
    }
  }
  div, p {
    display : inline-block;
  }
  p {
    font-size   : 16px;
    font-weight : 400;
    line-height : 48px;
    margin-left : 15px;
    color       : $blanc;
    @include transition(color, 0.25s);
    @media screen and (max-width : $mobile) {
      display : none;
    }
  }
  .icon-menu {
    font-size : 0;
    float     : left;
    @include icone('../images/icones/menu-w.png', 48px, 48px);
    @media screen and (max-width : $mobile) {
      width  : 36px;
      height : 36px;
    }
  }
  .not-front & {
    .icon-menu {
      background-image : url('../images/icones/menu-b.png');
    }
    p {
      color : #000000;
    }
  }
  &.white-icon {
    .icon-menu {
      background-image : url('../images/icones/menu-w.png');
    }
    p {
      color : #ffffff;
    }
  }
  .#{$univ-imaginaire} &,
  .#{$univ-polar} &,
  .#{$univ-litterature} &,
  .#{$univ-romance} &,
  .#{$home-preludes} &,
  .page-enseignant & {
    .icon-menu {
      background-image : url('../images/icones/menu-w.png');
    }
    p {
      color : #ffffff;
    }
  }
  .whiteBg &, .scrolledNav & {
    .icon-menu {
      background-image : url('../images/icones/menu-b.png');
    }
    p {
      color : #000000;
    }
  }
  .close-menu {
    background-image : none !important;
    @extend .fa;
    @extend .fa-times-circle;
    color            : #333333;
    font-size        : 48px;
    font-size        : 55px;
    line-height      : 50px;
    @media screen and (max-width : $mobile) {
      font-size : 36px;
    }
  }
  &.decal-close {
    right : 15px;
    left  : auto;
  }
}

#block-views-exp-recherche-page,
#block-views-exp-multi-index-search-page {
  max-width : 792px;
  @include rem(padding, 0 15px 0);
  @include rem(margin, 50px auto 40px);
  position  : relative;
  .views-exposed-form {
    position : relative;
  }
  #edit-search-api-views-fulltext-wrapper {
    width : 100%;
  }
  @media screen and (max-width : $mobile) {
    padding-top : 15px;
  }
  input {
    width         : 100%;
    outline       : none;
    background    : transparent;
    height        : 47px;
    border        : none;
    box-shadow    : none;
    border-bottom : thin solid $gris-moyen;
    border-radius : 0;
    font-size     : 18px;
    color         : $almost-noir;
    padding       : 0 50px 0 0;
    &::placeholder {
      color : $almost-noir;
    }
    &:focus {
      border        : none;
      border-bottom : thin solid $gris-moyen;
      box-shadow    : none;
    }
    @media screen and (max-width : $mobile) {
      font-size : 16px;
    }
    .#{$univ-polar} & {
      color : $blanc;
      &::placeholder {
        color : $blanc;
      }
    }
  }
  .views-submit-button {
    padding     : 0 5px 3px 15px;
    border-left : thin solid $gris-moyen;
  }
  button {
    @include icone('../images/icones/loupe.png', 27px, 27px);
    background-color : transparent;
    border           : none;
    &:hover {
      border : none;
    }
    &:after {
      display : none;
    }
  }
  #page-header & {
    display    : none;
    position   : absolute;
    top        : 145px;
    margin     : 0;
    left       : 50%;
    z-index    : 1;
    width      : 100vw;
    @include transform(translatex(-50%));
    background : white;
    max-width  : none;
    padding    : 25px;
    @media screen and (max-width : $mobile) {
      padding : 25px 0;
      top     : 80px;
    }
    .scrolledNav & {
      top : 80px;
      @media screen and (max-width : $mobile) {
        top : 55px;
      }
    }
    > .content {
      @extend .container;
    }
    form {
      width  : 75%;
      margin : 0 auto;
      @media screen and (max-width : $mobile) {
        width : 100%;
      }
    }
  }
}

.search-menu {
  position      : absolute;
  right         : 110px;
  top           : 0;
  margin-top    : 36px;
  width         : 36px;
  height        : 36px;
  border-radius : 50%;
  border        : thin solid $blanc;
  text-align    : center;
  &:hover {
    @include transform(scale(1.15));
  }
  @media screen and (max-width : $mobile) {
    right      : 65px;
    margin-top : 0;
  }
  @include transition(margin-top, 0.25s);
  a {
    color       : $blanc;
    line-height : 32px;
    font-size   : 21px;
    &:focus {
      text-decoration : none;
    }
  }
  .scrolledNav & {
    margin-top : 20px;
    @media screen and (max-width : $mobile) {
      margin-top : 0;
    }
  }
  .not-front & {
    border-color : black;
    a {
      color : black;
    }
  }
  &.white-icon {
    border-color : #ffffff;
    a {
      color : #ffffff;
    }
  }
  .#{$univ-imaginaire} &,
  .#{$univ-polar} &,
  .#{$univ-litterature} &,
  .#{$univ-romance} &,
  .#{$home-preludes} &,
  .page-enseignant & {
    border-color : $blanc;
    a {
      color : $blanc;
    }
  }
  .whiteBg &, .scrolledNav & {
    border-color : black;
    a {
      color : black;
    }
  }
}

.user-button {
  position   : absolute;
  right      : 60px;
  top        : 0;
  margin-top : 36px;
  &:hover {
    @include transform(scale(1.15));
  }
  @media screen and (max-width : $mobile) {
    right      : 15px;
    margin-top : 0;
  }
  @include transition(margin-top, 0.25s);
  .scrolledNav & {
    margin-top : 20px;
    @media screen and (max-width : $mobile) {
      margin-top : 0;
    }
  }
  .user-icon {
    @include icone('../images/icones/user-w.png', 36px, 36px);
    display : inline-block;
    color   : transparent;
    @media screen and (max-width : $mobile) {
      width  : 36px;
      height : 36px;
    }
  }
  .not-front & {
    .user-icon {
      background-image : url('../images/icones/user-b.png');
    }
  }
  &.white-icon {
    .user-icon {
      background-image : url('../images/icones/user-w.png');
    }
  }
  .#{$univ-imaginaire} &,
  .#{$univ-polar} &,
  .#{$univ-litterature} &,
  .#{$univ-romance} &,
  .#{$home-preludes} &,
  .page-enseignant & {
    .user-icon {
      background-image : url('../images/icones/user-w.png');
    }
  }
  .whiteBg &, .scrolledNav & {
    .user-icon {
      background-image : url('../images/icones/user-b.png');
    }
  }
}

.nl-rs {
  padding : 0;
  @media screen and (max-width : $desk-inter) {
    margin : 0;
    width  : 100%;
  }
  @media screen and (max-width : $mobile) {
    text-align : center;
  }
  .newsletter-footer {
    float         : left;
    border-radius : 20px;
    border        : thin solid $blanc;
    height        : 40px;
    margin        : 25px 0;
    display       : inline-block;
    @media screen and (max-width : $min-mobile) {
      margin : 15px 0;
      float  : none;
    }
    a {
      color          : $blanc;
      @extend .Lien;
      letter-spacing : 1.68px;
      line-height    : 40px;
      text-transform : uppercase;
      margin         : 0 25px;
      @media screen and (max-width : $mobile) {
        letter-spacing : 1.04px;
        white-space    : nowrap;
        margin         : 0 10px;
      }
    }
    .fa {
      font-size : 17px;
      color     : $blanc;
      &:before {
        margin-right : 15px;
        @media screen and (max-width : $mobile) {
          margin-right : 5px;
        }
      }
    }
    &:hover {
      background : $blanc;
      a,
      .fa {
        color : $rouge;
      }
    }
  }
  .social-footer {
    float     : right;
    margin    : 25px 0;
    display   : inline-block;
    font-size : 0;
    @media screen and (max-width : $min-mobile) {
      margin : 0 0 15px;
      float  : none;
    }
    a {
      color         : $blanc;
      width         : 40px;
      height        : 40px;
      border-radius : 50%;
      border        : thin solid $blanc;
      margin-left   : 20px;
      line-height   : 36px;
      text-align    : center;
      font-size     : 0;
      &:first-child {
        margin : 0;
      }
      &:hover {
        text-decoration : none;
        color           : $rouge;
        background      : $blanc;
      }
      &:before {
        font-size : 20px;
      }
    }
  }
}

#block-views-top-recherche-block,
#block-views-top-recherche-block-1 {
  max-width : 792px;
  margin    : 0 auto 50px;
  display   : inline-block;
  width     : 100%;
  padding   : 0 15px;
  position  : relative;
  @media screen and (max-width : $mobile) {
    margin-top : 15px;
  }
  .block-title-wrapper {
    margin-right : 15px;
    text-align   : center;
    h2 {
      color          : $gris-moyen;
      text-transform : uppercase;
      font-size      : 13px;
      margin-bottom  : 20px;
    }
    @media screen and (max-width : $mobile) {
      text-align : center;
      width      : 100%;
    }
  }
  .view-content {
    font-size  : 0;
    text-align : center;
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
    div {
      display : inline-block;
    }
    a {
      float          : left;
      padding        : 10px 20px;
      background     : $gris-clair;
      color          : $gris-sombre;
      text-transform : uppercase;
      font-size      : 13px;
      margin         : 10px 10px 0 0;
    }
  }
  #page-header & {
    display    : none;
    position   : absolute;
    top        : 249px;
    margin     : 0;
    left       : 12.5%;
    width      : 75%;
    background : white;
    max-width  : none;
    padding    : 0 25px 25px;
    .block-title-wrapper {
      display        : inline-block;
      vertical-align : top;
    }
    .content {
      display : inline-block;
    }
    @media screen and (max-width : $mobile) {
      left    : 0;
      width   : 100%;
      padding : 0 15px 25px;
      top     : 184px;
    }
    &:before {
      content    : "";
      position   : absolute;
      left       : 50%;
      top        : 0;
      bottom     : 0;
      width      : 100vw;
      @include transform(translatex(-50%));
      background : white;
      z-index    : -1;
    }
    .scrolledNav & {
      top : 184px;
      @media screen and (max-width : $mobile) {
        top : 159px;
      }
    }
  }
}

.bloc-pre-comment {
  background : $orange;
  @include rem(padding, 75px);
  @include bandeau-full-width($orange);
  @media screen and (max-width : $tab) {
    padding : 15px 15px;
  }
  .content {
    display : inline-block;
  }
  p {
    color : $blanc;
  }
  .lien-cartouche {
    a {
      display        : inline-block;
      @include rem(padding, 15px 25px);
      border         : thin solid $blanc;
      text-transform : uppercase;
      color          : $blanc;
      font-size      : 14px;
      font-weight    : 400;
      letter-spacing : 1.68px;
      border-radius  : 999px;
      &:hover {
        background      : $blanc;
        color           : $orange;
        border-color    : transparent;
        text-decoration : none;
      }
    }
  }
  .col-sm-4 {
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
    p {
      text-align : left;
      @media screen and (max-width : $mobile) {
        text-align : center;
      }
      &:first-child {
        font-size   : 30px;
        font-weight : 200;
      }
      &:nth-child(2) {
        font-size   : 50px;
        font-weight : 800;
      }
      &:nth-child(3) {
        font-size : 15px;
      }
    }
  }
  .col-sm-2 {
    text-align     : center;
    vertical-align : top;
    p {
      text-transform : uppercase;
      font-size      : 13px;
      letter-spacing : 2.6px;
    }
    @media screen and (max-width : $mobile) {
      width : 50%;
      float : left;
    }
    div {
      display       : inline-block;
      margin-bottom : 15px;
      p {
        font-size : 0;
      }
    }
  }
  .icone-cadeau {
    @include icone('../images/icones/gift.png', 80px, 80px);
  }
  .icone-bulles {
    @include icone('../images/icones/bulles.png', 80px, 80px);
  }
  .icone-liste {
    @include icone('../images/icones/list.png', 80px, 80px);
  }
  .icone-ampoule {
    @include icone('../images/icones/light.png', 80px, 80px);
  }
}

#block-quicktabs-avis-homepage {
  background : $orange;
  @include rem(padding, 75px);
  @include bandeau-full-width($orange);
  @media screen and (max-width : $tab) {
    padding : 15px 15px 50px;
  }
}

.block-social-counters {
  padding-bottom : 50px;
  max-width      : 820px;
  margin         : 0 auto;
  border-bottom  : thin solid $almost-noir;
  @media screen and (max-width : $mobile) {
    padding-bottom : 25px;
    margin         : 0 15px;
  }
  .block-title-wrapper {
    margin     : 50px 0;
    text-align : center;
    h2 {
      text-transform : uppercase;
    }
    @media screen and (max-width : $mobile) {
      margin-bottom : 25px;
    }
    p {
      color : $almost-noir;
      @extend .GrosTitreLight;
      strong {
        font-weight : 800;
      }
    }
  }
  .content {
    //width   : 100%;
    display : inline-block;
  }
  .item {
    //float      : left;
    max-width  : 33%;
    display    : inline-block;
    position   : relative;
    text-align : center;
    height     : 110px;
    width      : 270px;
    &.facebook {
    //  display : none;
    }
    .title a {
      @extend .Mentions;
      letter-spacing : 2.61px;
      position       : absolute;
      bottom         : 0;
      width          : 100%;
      left           : 0;
      color          : $almost-noir;
    }
    .number {
      @extend .GrosTitreBold;
      position : absolute;
      left     : 0;
      width    : 100%;
      top      : 45%;
    }
  }
  .icone-social-counter {
    position : absolute;
    width    : 100%;
    bottom   : 60px;
    left     : 0;
    color    : $almost-noir;
  }
  .facebook .icone-social-counter {
    @extend .fa;
    @extend .fa-facebook;
    font-size : 45px;
  }
  .twitter .icone-social-counter {
    @extend .fa;
    @extend .fa-twitter;
    font-size : 56px;
  }
  .instagram .icone-social-counter {
    @extend .fa;
    @extend .fa-instagram;
    font-size : 52px;
  }
}

#block-views-autopromo-block {
  margin-bottom : 35px;
  overflow      : hidden;

  .node.view-mode-full & {
    margin-bottom : 0;
  }

  img {
    @media screen and (max-width : $mobile) {
      max-width : 160%;
      left      : -45%;
      position  : relative;
    }
  }
}

.block-facetapi,
.block-current-search {
  text-align : center;
  .block-title {
    color          : $almost-noir;
    @extend .TitreRubr;
    letter-spacing : 6.3px;
    margin-bottom  : 30px;
    /*@media screen and (max-width : $mobile) {
      font-size      : 13px;
      letter-spacing : 1.56px;
      display        : inline-block;
      @include rem(padding, 14px 75px);
      border-radius  : 999px;
      border         : thin solid $almost-noir;
      line-height    : 13px;
      cursor         : pointer;
      margin-bottom  : 15px;
    }*/

    .liste-livre & {
      margin         : 0;
      text-align     : left;
      letter-spacing : 0;
      padding        : 1rem 2rem;
      background     : $gris-clair;
      .#{$univ-romance} & {
        background : $romance;
      }
      .#{$univ-polar} & {
        background : $polar;
        color      : $blanc;
      }
      .#{$univ-imaginaire} & {
        background : $imaginaire;
      }
      .#{$univ-litterature} & {
        background : $litterature;
      }
    }
  }
  .content {
    @media screen and (max-width : $mobile) {
      display : none;
    }
    > ul {
      padding    : 0;
      margin     : 0;
      text-align : left;
      font-size  : 0;
    }
    li {
      display     : inline-block;
      text-align  : left;
      width       : 20%;
      float       : left;
      font-size   : 15px;
      white-space : nowrap;
      .page-enseignant & {
        width : auto;
      }
      .liste-livre & {
        display     : inline-block;
        width       : 100%;
        margin      : 3px 0;
        float       : none;
        white-space : normal;
      }
      @media screen and (max-width : $desk-inter) {
        width : 25%;
      }
      @media screen and (max-width : $mobile) {
        width : 100%;
        .page-recherche & {
          padding : 0 15px;
        }
      }
    }
    input {
      display : none;
    }
    a {
      @extend .TexteCourant;
      color          : $almost-noir;
      line-height    : 25px;
      vertical-align : middle;
      font-size      : 14px;
    }
    a:before {
      content        : '';
      width          : 20px;
      height         : 20px;
      display        : inline-block;
      margin-right   : 10px;
      background     : $blanc;
      vertical-align : middle;
      /*.page-recherche &,
      .page-taxonomy-term &,
      .page-enseignant &,
      .page-prix-litteraires &,
      .page-adaptation-cinema &,
      .page-ebooks & {*/
      border         : thin solid $almost-noir;
      /*}*/
    }
    a.facetapi-active {
      display   : inline-block !important;
      font-size : 0;
      span {
        font-size : 0;
        position  : relative !important;
      }
      span:before {
        content      : "";
        left         : -23px;
        top          : -9px;
        width        : 7px;
        height       : 16px;
        border       : solid $almost-noir;
        border-width : 0 2px 2px 0;
        position     : absolute;
        @include transform(rotate(45deg));
      }
    }

    .current-search-item-active ul {
      margin  : 0;
      padding : 0;

      li {
        color    : $rouge;
        position : relative;
        margin   : 0;
        a {
          display     : inline-block !important;
          font-size   : 0;
          line-height : 18px;

          &:before {
            border       : none;
            margin-right : 0;
          }
          span {
            font-size : 0;
            position  : relative !important;
          }
          span:before {
            position    : absolute;
            top         : -10px;
            left        : -20px;
            content     : "\274c"; /* use the hex value here... */
            font-size   : 12px;
            color       : $rouge;
            line-height : 18px;
            text-align  : center;
            font-weight : 300;
          }
        }
      }
    }
  }
  .select-zone & {
    //margin-right : 30px;
    display : inline-block;
    width   : 100%;
  }

  &.mots-cles {
      display: none;
  }

  .view-id-thematique.view-display-id-block_1 & {
    ul.facetapi-facet-hw-livre-themes {
      > li {
        font-size : 0;

        > a {
          display : none !important;
        }

        > ul {
          padding-left : 0;
        }
      }
    }
  }
}

.block-facet-select,
.block-current-search {
  //margin-right : 30px;
  display : inline-block;
  @media screen and (max-width : $mobile) {
    margin-left : 15px;
  }
  &:last-child {
    margin-right : 0;
  }
  .facetapi-facetapi-select-dropdowns {
    width : 193px;
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
  }

  .liste-livre & {
    .content {
      padding : 1rem;
      border  : 1px solid $gris-sombre;
      @media screen and (max-width : $mobile) {
        display : none;
      }

      .current-search-item-results {
        display : none;
      }

      .facetapi-facetapi-checkbox-links {
        li {
          display : inline-block;
          width   : 100%;
          margin  : 3px 0;

          ul {
            padding-left : 25px;
          }
        }
      }
      .facetapi-limit-link {
        display        : inline-block;
        width          : 100%;
        text-align     : right;
        color          : $rouge;
        text-transform : uppercase;

        &:before {
          display : none;
        }
      }
    }
  }
}

.block-current-search {
  .liste-livre & {
    width      : 100%;
    background : $blanc;
    padding    : 1rem 1.5rem;
    border     : 1px solid $gris-sombre;
    @media screen and (max-width : $mobile) {
      display : none;
    }

    .block-title {
      padding       : 0;
      font-weight   : 700;
      color         : $rouge !important;
      background    : $blanc !important;
      margin-bottom : 5px;
    }

    .content {
      padding : 0;
      border  : none;
    }
  }
}

.region-blockgroup-actuellement {
  background : $gris-clair;
  @include rem(padding, 50px 45px);
  display    : inline-block;
  width      : 100%;
  @include bandeau-full-width($gris-clair);
  @media screen and (max-width : $mobile) {
    padding : 15px 0;
  }
  .block {
    @include rem(margin-bottom, 30px);
    float : left !important;
    @media screen and (max-width : $mobile) {
      margin-bottom : 20px;
      max-width     : 100%;
    }
    &.actuellement-title {
      width      : 100%;
      padding    : 0 15px;
      text-align : center;
      p {
        @extend .GrosTitreLight;
        color : $almost-noir;
      }
      strong {
        font-weight : 800;
        @media screen and (max-width : $min-mobile) {
          display : block;
        }
      }
    }
    &.actuellement-cc-lib,
    &.actuellement-actu {
      @extend .col-sm-6;
      @media screen and (max-width : $tab) {
        width : 100%;
      }
    }
    &.actuellement-agenda {
      @extend .col-sm-3;
      clear : both;
      @media screen and (max-width : $tab) {
        width : 50%;
      }
      @media screen and (max-width : $mobile) {
        width : 100%;
      }
    }
    &.actuellement-select-jeux {
      @extend .col-sm-5;
      @media screen and (max-width : $tab) {
        width : 50%;
      }
      @media screen and (max-width : $mobile) {
        width : 100%;
      }
    }
    &.actuellement-promo {
      @extend .col-sm-4;
      @media screen and (max-width : $tab) {
        width : 50%;
        left  : 25%;
      }
      @media screen and (max-width : $mobile) {
        width : 100%;
        left  : 0;
      }
    }
  }
}

#block-views-theme-slider-home-block {
  margin  : 75px 0 45px;
  padding : 0 10px;
}

.block-title-social-feeds {
  @include rem(padding-top, 40px);
  position : relative;
  @include rem(padding-bottom, 40px);
  p {
    @extend .GrosTitreLight;
    color : $almost-noir;
    strong {
      font-weight : 800;
    }
  }
  &:after {
    content    : '';
    position   : absolute;
    bottom     : 0;
    left       : 12.5%;
    width      : 75%;
    height     : 1px;
    background : $gris-sombre;
  }
}

.breadcrumb {
  background : transparent;
  padding    : 30px 15px 0;
  text-align : left;
  color      : $blanc;
  @extend .FilArrianne;
  @media screen and (max-width : $mobile) {
    display : none;
  }
  li,
  a,
  span {
    @extend .FilArrianne;
    color : $blanc;
    &.active {
      color : $blanc;
    }
  }
  > li + li::before {
    content : "»";
    color   : $blanc;
  }
  .page-node.node-type-hw-livre & {
    max-width : 1100px;
    margin    : 0 auto;
    color     : #aaaaaa;
    li,
    a,
    .active,
    span {
      color : #aaaaaa;
    }
    li::before {
      color : #aaaaaa;
    }
  }
}

.bloc-title-enseignant {
  margin    : 25px auto;
  max-width : 890px;
  padding   : 0 15px;
  .label-above {
    font-size      : 30px;
    color          : $almost-noir;
    font-weight    : 200;
    text-transform : uppercase;
    @media screen and (max-width : $mobile) {
      font-size : 20px;
    }
  }
  .title {
    color       : $almost-noir;
    font-size   : 50px;
    font-weight : 800;
    @media screen and (max-width : $mobile) {
      font-size : 30px;
    }
  }
}

#block-views-livres-block-13 {
  margin : 25px 0 75px;
}

.block-title-wrapper {
  .field-name-liste-selection-thematique & {
    text-align : left;
    h2 {
      font-size      : 22px;
      font-weight    : 800;
      color          : $almost-noir;
      text-transform : uppercase;
    }
  }
}

.block-title {
  .node-hw-livre.view-mode-full &,
  .node-hw-intervenant.view-mode-full & {
    @extend .GrosTitreBold;
    color         : $almost-noir;
    font-size     : 25px;
    margin-bottom : 15px;
    @media screen and (max-width : $mobile) {
      text-align : center;
    }
  }
}

#user-login-form,
#user_login_block,
.popin-cancel,
.field-name-popin-connexion-cont-pedagogique {
  display  : none;
  position : fixed;
  top      : 25%;
  width    : 50%;
  left     : 25%;
  @media screen and (max-width : $mobile) {
    width   : 100%;
    padding : 0 15px;
    left    : 0;
  }
  > div {
    //background    : #ffffff;
    padding       : 50px 25px 25px;
    position      : relative;
    border-radius : 0;
    @media screen and (max-width : $mobile) {
      padding : 40px 15px 15px;
    }
    .close-log {
      position : absolute;
      top      : 15px;
      right    : 25px;
      @include icone('../images/icones/close.png', 16px, 16px);
      cursor   : pointer;
    }
    a {
      color       : #777777;
      font-weight : 300;
      &:hover {
        color : #333333;
      }
    }
  }
  &:before {
    content    : "";
    position   : fixed;
    background : rgba(0, 0, 0, .25);
    width      : 100%;
    height     : 100%;
    top        : 0;
    left       : 0;
    z-index    : -1;
  }
}

#user_login_block {
  > div {
    padding    : 50px 25px 25px;
    background : #ffffff;
  }
}

.popin-cancel {
  display    : block;
  text-align : center;
  span {
    width         : 100%;
    display       : inline-block;
    margin-bottom : 25px;
  }
  div a,
  button {
    margin         : 0 15px;
    padding        : 15px 30px;
    text-transform : uppercase;
    border-radius  : 999px;
    border         : thin solid $rouge;
    color          : #ffffff;
    background     : $rouge;
    &:hover {
      background : transparent;
      color      : $rouge;
    }
  }
}

.bloc-avantage-form {
  margin     : 50px -50px;
  padding    : 50px 25px;
  background : $gris-clair;
  @media screen and (max-width : $tab) {
    margin : 50px 0;
  }

  .col-left {
    text-align     : left;
    vertical-align : top;
    h2 {
      font-size   : 40px;
      font-weight : 300;
      line-height : 60px;
      color       : $almost-noir;
    }
  }
  .col-right {
    text-align : left;
    b {
      font-size   : 18px;
      line-height : 48px;
      color       : $almost-noir;
    }
    ul {
      padding-left     : 25px;
      list-style-image : url('../images/icones/red-check.png');
      margin-top       : 25px;
    }
    li {
      color          : $almost-noir;
      font-size      : 16px;
      padding-bottom : 25px;
    }
  }
}

#comments {
  .comment-load {
    i {
      color     : #ffffff;
      font-size : 20px;
    }
    p {
      color : #ffffff;
    }
  }
}

#block-reachfive-login-reach5-block {
  position   : fixed;
  top        : 0;
  left       : 0;
  width      : 100%;
  height     : 100%;
  background : rgba(0, 0, 0, 0.25);
  display    : none;
  text-align : center;
  .not-logged-in.page-user & {
    display : block;
  }

  .content {
    position   : relative;
    display    : inline-block;
    top        : 150px;
    padding    : 25px 0 0;
    background : #ffffff;
    .descript {
      padding : 15px 15px 0;
    }
  }
  .close-log {
    position : absolute;
    top      : 15px;
    right    : 25px;
    @include icone('../images/icones/close.png', 16px, 16px);
    cursor   : pointer;
  }
}

.region-blockgroup-social-feeds {
  .block-views {
    display        : inline-block;
    width          : 25%;
    vertical-align : top;
  }
}
