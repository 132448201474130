.field-name-body,
.taxonomy-term-description,
.field-name-hw-desc-contenu {
  text-align : left;
  div, p, span, a {
    color : $almost-noir;
    @extend .TexteCourant;
  }
  a {
    text-decoration : underline;
  }
  .view-mode-full & {
    @include rem(margin, 35px 0 50px);
    width   : 100%;
    display : inline-block;
    @media screen and (max-width : $mobile) {
      @include rem(margin, 25px 0 45px);
    }
  }
  .view-mode-recherche &,
  .view-mode-teaser &,
  .view-mode-accroche & {
    @include rem(margin, 5px 0);
    .view-social-content & {
      margin-top : 0;
    }
  }
  .view-mode-teaser_home & {
    margin-top  : 20px;
    font-weight : 200;
  }
  .node-coup-coeur-libraire.view-mode-teaser_home & {
    .field-item {
      &:before,
      &:after {
        content : '"';
      }
    }
  }
  .node-promo-en-cours.view-mode-recherche & {
    height   : 60px;
    overflow : hidden;
    p {
      margin-bottom : 0;
      @include transitions(margin-bottom 0.25s);
    }
    .reset-margin {
      margin-bottom : 10px;
    }
  }
  .node-webform.view-mode-full & {
    max-width : 740px;
    margin    : 50px auto;
    p {
      font-size   : 20px;
      color       : $almost-noir;
      font-weight : 200;
    }
  }
  .node-coup-coeur-libraire.view-mode-teaser_livre & {
    margin : 15px 0;
  }

  .node-contenu-pedagogique.view-mode-block_teaser &,
  .paragraphs-item-contenus-complementaires & {
    margin : 25px 0;
  }

  .node-page.view-mode-full & {
    text-align : initial;
    width      : 100%;
    .lpd {
      text-align : center;
    }
  }
  .node-webform.view-mode-full & {
    div, p, span, a {
      color       : inherit;
      font-size   : inherit;
      font-weight : inherit;
      font-family : "lft-etica", sans-serif;
    }
  }
  .node-selection-thematique.view-mode-full &{
    @include rem(margin, 25px 0 15px);
  }
}

.field-name-title-field,
.field-name-title,
.field-name-hw-livre-titre-couv,
.field-name-titre-theme {
  a {
    color : $almost-noir;
  }
  @media screen and (max-width : $mobile) {
    margin-top : 15px;
  }
  text-align : left;
  .view-mode-full & {
    text-align : center;
    h1, h2, h3, h4, h5, h6 {
      color : $blanc;
      @extend .GrosTitreBold;
    }
  }
  .view-mode-recherche &,
  .node-hw-livre.view-mode-teaser_livre & {
    margin-bottom : 5px;
    div, a, p, span, h2 {
      margin : 0;
      @extend .TitreArt;
    }
  }
  .node-agenda.view-mode-recherche & {
    a {
      font-size : 15px;
    }
    h2 {
      margin : 0;
    }
  }
  .view-mode-teaser &,
  .view-mode-teaser_home &,
  .view-mode-accroche & {
    text-align : left;
    @extend .TitreArt;
    h3, h2 {
      margin : 0;
    }
    div, a, p, span {
      @extend .TitreArt;
    }
    @include rem(margin-bottom, 5px);
    @media screen and (max-width : $mobile) {
      margin-top : 0;
    }
  }
  .node-video.view-mode-teaser_home & {
    margin-bottom : 0;
    position      : absolute;
    bottom        : 0;
    z-index       : 1;
    max-width     : 280px;
    padding       : 15px;
    div, a {
      @extend .Lien;
      color : #ffffff;
    }
    div {
      font-size   : 15px;
      font-weight : 400;
    }
  }
  .node-agenda.view-mode-teaser_home & {
    h2 {
      font-size   : 15px;
      font-weight : bold;
    }
  }
  .view-mode-teaser & {
    h2, a {
      margin-top : 0;
      color      : $almost-noir;
    }
  }
  .taxonomy-term.view-mode-recherche & {
    margin-top : 0;
    h2 {
      margin-top : 0;
    }
  }
  .vocabulary-hw-themes.view-mode-full & {
    .label-above {
      .page-taxonomy-term & {
        display : none;
      }
      .#{$univ-imaginaire} &,
      .#{$univ-litterature} &,
      .#{$univ-romance} &,
      .#{$univ-polar} & {
        display        : block;
        font-size      : 30px;
        color          : $almost-noir;
        font-weight    : 200;
        text-transform : uppercase;
      }
      .#{$univ-polar} & {
        color : $blanc;
      }
    }
    h2 {
      font-size   : 50px;
      line-height : 55px;
    }
  }
  .#{$univ-polar} & {
    a {
      color : $blanc;
    }
  }
  .field-collection-item-field-livre-selection.view-mode-full & {
    text-align : left;
    @extend .TitreArt;
    color      : $almost-noir;
    margin     : 5px 0;
    a {
      font-size : 25px;
    }
  }
  .node-hw-intervenant.view-mode-listes & {
    h2, a {
      color       : $rouge;
      font-size   : 15px;
      font-weight : 700;
      margin-top  : 0;
    }
  }
  .node-hw-livre.view-mode-full & {
    text-align : left;
    @media screen and (max-width : $mobile) {
      text-align : center;
    }
    h1 {
      @extend .GrosTitreBold;
      color : $almost-noir;
    }
  }
  .node.view-mode-liste_slick & {
    width      : 100%;
    margin-top : 5px;
    text-align : center;
    display    : inline-block;
    a {
      @extend .SousTitre;
    }
  }
  .node-webform.view-mode-full & {
    @include bandeau-full-width($rouge);
    padding : 15px 0 75px;
  }
  .node-video.view-mode-teaser_livre & {
    position   : absolute;
    bottom     : 0;
    padding    : 20px 30px;
    background : rgba(0, 0, 0, 0.5);
    width      : 100%;
    text-align : left;
    @media screen and (max-width : $mobile) {
      text-align : left;
      padding    : 15px;
    }
    a {
      color       : #ffffff;
      font-size   : 15px;
      font-weight : 300;
    }
  }
  .comment-form & {
    input {
      margin-bottom : 25px;
      border        : none;
      border-radius : 20px;
      height        : 54px;
      color         : $almost-noir;
      font-size     : 15px;
    }
  }
  .view-mode-accroche &,
  .view-mode-teaser & {
    h2 {
      margin-top  : 0;
      line-height : 1;
    }
  }

  .node-agenda.view-mode-teaser &,
  .node-article.view-mode-teaser &,
  .node-coup-coeur-libraire.view-mode-teaser_livre &,
  .node-jeux.view-mode-teaser & {
    @media screen and (max-width : $min-mobile) {
      text-align : left;
    }

    h2 {
      @media screen and (max-width : $min-mobile) {
        text-transform : initial;
      }
    }
  }
}

.field-name-field-image {
  @media screen and (max-width : $mobile) {
    text-align    : left;
    display       : inline-block;
    margin-bottom : 20px;
  }
  .view-mode-teaser & {
    width : 100%;
  }
  .node-coup-coeur-libraire.view-mode-teaser & {
    @media screen and (max-width : $mobile) {
      img {
        max-height : 250px;
        width      : auto;
      }

    }
  }
  .vocabulary-hw-themes.view-mode-teaser_home & {
    border-radius : 50%;
    overflow      : hidden;
    max-width     : 190px;
    max-height    : 190px;
    margin        : 0 auto;
  }
  .node-habillage-publicitaire.view-mode-teaser & {
    margin : 0;
    img {
      width : 100%;
      @media screen and (max-width : $mobile) {
        width     : auto;
        max-width : none;
        height    : 100px;
        left      : -50%;
      }
    }
  }
  .vocabulary-hw-serie.view-mode-full & {
    @media screen and (max-width : $mobile) {
      padding : 0 15px;
    }
  }
  &.jeux-finis {
    position : relative;
    &:before {
      content    : "";
      width      : 100%;
      height     : 100%;
      left       : 0;
      top        : 0;
      position   : absolute;
      background : rgba(0, 0, 0, 0.33);
    }
  }
}

.field-name-field-image-principal {
  display : block;
  @media screen and (max-width : $mobile) {
    display : none;
  }
}

.field-name-field-image-mobile {
  display : none;
  @media screen and (max-width : $mobile) {
    display : block;
  }
}

.field-name-field-video {
  .node-video.view-mode-teaser_home & {
    width    : 288px;
    height   : 185px;
    position : relative;
    overflow : hidden;
    @media screen and (max-width : $mobile) {
      width     : 100%;
      max-width : 338px;
    }
    img {
      max-width : none;
      position  : absolute;
      z-index   : -1;
      top       : 50%;
      left      : 50%;
      @include transform(translate(-50%, -50%));
    }
    &:before {
      content    : "";
      background : black;
      opacity    : 0.5;
      width      : 100%;
      height     : 100%;
      position   : absolute;
      left       : 0;
      top        : 0;
    }
  }
  .node-video.view-mode-full & {
    @extend .responsive-video;
  }
  .node-video.view-mode-recherche & {
    position : relative;
    &:before {
      content  : "";
      position : absolute;
      @include icone('../images/icones/play.png', 59px, 59px);
      z-index  : 1;
      left     : calc(50% - 30px);
      top      : calc(50% - 30px);
    }
  }
  .paragraphs-items & {
    margin : 50px 0;
    @media screen and (max-width : $mobile) {
      margin : 15px 0;
    }
  }
  .view-mode-teaser_livre & {
    a:before {
      content           : "";
      position          : absolute;
      background-image  : url("../images/icones/play.png");
      width             : 87px;
      height            : 88px;
      background-size   : contain;
      background-repeat : no-repeat;
      z-index           : 1;
      left              : calc(50% - 43px);
      top               : calc(50% - 44px);
      @media screen and (max-width : $mobile) {
        width  : 50px;
        height : 50px;
        left   : calc(50% - 25px);
        top    : calc(50% - 25px);
      }
    }
  }
  .node-video.view-mode-teaser & {
    margin-bottom : 15px;
  }
}

.field-name-post-date,
.field-name-hw-livre-date-parution {
  .view-mode-full & {
    color          : $blanc;
    @extend .TitreRubr;
    font-size      : 20px;
    text-transform : lowercase;
  }
  .view-mode-teaser &,
  .view-mode-recherche &,
  .view-mode-teaser_livre & {
    color          : $almost-noir;
    text-align     : left;
    margin-bottom  : 5px;
    @extend .SousTitre;
    font-size      : 15px;
    text-transform : lowercase;
    @media screen and (max-width : $mobile) {
      font-size : 14px;
    }
  }
  .node-instagram.view-mode-teaser &,
  .node-facebook.view-mode-teaser &,
  .node-tweet.view-mode-teaser &,
  .node-youtube.view-mode-teaser & {
    display     : inline-block;
    @extend .TexteCourant;
    color       : $almost-noir;
    opacity     : 0.5;
    margin-left : 5px;
    font-weight : 400;
  }
  .view-mode-liste_slick & {
    margin-top : 5px;
    span {
      color       : $gris-sombre;
      font-size   : 15px;
      font-weight : 200;
      text-align  : center;
      margin-top  : 5px;
    }
  }
  .node-hw-livre.view-mode-recherche &,
  .node-hw-livre.view-mode-teaser_livre & {
    vertical-align : middle;
    color          : $gris-sombre;
    font-weight    : 200;
    font-size      : 15px;
    margin-bottom  : 0;
    display        : inline-block;
    &:after {
      content : ' ';
    }
  }
  .#{$univ-polar} & {
    color : $gris-moyen;
  }
  .comment & {
    color       : $almost-noir;
    font-size   : 13px;
    font-weight : 400;
  }
  .comment.view-mode-teaser_user & {
    float : left;
    clear : both;
  }
  .node-hw-livre.view-mode-teaser &,
  .node-hw-livre.view-mode-livre_selection_themat & {
    float       : left;
    margin      : 0 15px 5px 0;
    line-height : 15px;
    span {
      color       : $gris-sombre;
      font-size   : 15px;
      font-weight : 200;
    }
  }
  .node-hw-livre.view-mode-livre_selection_themat & {
    float      : none;
    text-align : left;
  }
}

.field-name-node-link,
.field-name-more-link {
  text-align : left;
  span, a {
    color : $almost-noir;
    @extend .Lien;
    &:after {
      content        : "";
      display        : inline-block;
      @include icone('../images/icones/arrow-link.png', 21px, 18px);
      margin-left    : 10px;
      vertical-align : middle;
      .node-coup-coeur-libraire & {
        background-image : url('../images/icones/arrow-link-w.png');
      }
    }
  }
  .view-mode-teaser_home & {
    margin-top : 5px;
  }
  .view-mode-teaser & {
    margin-top : 25px;
  }
  .view-mode-recherche & {
    margin-top : 25px;
  }
  .taxonomy-term.view-mode-recherche.#{$univ-polar} & {
    span, a {
      color : white;
    }
  }
  .node-coup-coeur-libraire.view-mode-teaser_home &,
  .node-coup-coeur-libraire.view-mode-recherche &,
  .node-coup-coeur-libraire.view-mode-teaser & {
    color : $blanc;
    @extend .Lien;
    &:after {
      content        : "";
      display        : inline-block;
      @include icone('../images/icones/arrow-link-w.png', 21px, 18px);
      margin-left    : 10px;
      vertical-align : middle;
    }
  }

  .node-article.view-mode-teaser_home &,
  .node-coup-coeur-libraire.view-mode-teaser_home & {
    position : absolute;
    bottom   : 0;
    @media screen and (max-width : $mobile) {
      position   : relative;
      margin-top : 25px;
    }
  }
  .taxonomy-term.view-mode-accroche & {
    @include cartouche(12, 400);
    text-align : left;
    a:hover {
      color : $blanc !important;
    }
    a:after {
      display : none;
    }
  }
}

.field-group-div {
  &.group-titre-date {
    display        : inline-block;
    width          : 100%;
    padding-bottom : 30px;
    @include rem(margin-bottom, 90px);
    @include bandeau-full-width($rouge);
    text-align     : center;
    @media screen and (max-width : $mobile) {
      margin-bottom : 15px;
    }
    .node-page.view-mode-full & {
      margin-bottom : 25px;
    }
  }
  &.group-label-social {
    font-size  : 0;
    width      : 100%;
    text-align : left;
    h3 {
      display : inline-block;
    }
    @media screen and (max-width : $mobile) {
      text-align : center;
    }
    &:before {
      font-size    : 25px;
      margin-right : 5px;
      color        : $almost-noir;
    }
    span {
      display : inline-block;
      @extend .TexteCourant;
      color   : $almost-noir;
      opacity : 0.5;
    }
  }
  &.group-date-theme {
    .view-mode-recherche &,
    .node-hw-livre.view-mode-teaser_livre & {
      display       : inline-block;
      width         : 100%;
      text-align    : left;
      margin-bottom : 5px;
    }
  }
  &.group-bottom {
    .taxonomy-term.view-mode-recherche &,
    .node-promo-en-cours & {
      @extend .shadow-transition;
      padding    : 25px;
      background : $blanc;
      position   : relative;
      overflow   : hidden;
      text-align : center;
      @media screen and (max-width : $mobile) {
        padding : 15px;
      }
    }
    .taxonomy-term.view-mode-recherche.#{$univ-imaginaire} & {
      background : $imaginaire;
    }
    .taxonomy-term.view-mode-recherche.#{$univ-polar} & {
      background : $polar;
    }
    .taxonomy-term.view-mode-recherche.#{$univ-litterature} & {
      background : $litterature;
    }
    .taxonomy-term.view-mode-recherche.#{$univ-romance} & {
      background : $romance;
    }
  }
  &.group-achat {
    .field-collection-item-field-livre-selection.view-mode-full & {
      margin-top : 25px;
      text-align : left;
    }
  }
  &.group-couv-picto,
  &.group-image-picto {
    display  : inline-block;
    position : relative;
    width    : 100%;
    .node-hw-livre.view-mode-full &,
    .liste-livre & {
      width : auto;
    }
    .view-pal & {
      @media screen and (max-width : $mobile) {
        max-width : 50%;
        float     : left;
      }
    }
    @media screen and (max-width : $mobile) {
      width : auto;
    }
  }
  &.group-image-picto {
    margin-top : 25px;
  }
  &.group-header-taxo {
    width : 100%;
    .field-name-title {
      .#{$univ-imaginaire} &,
      .#{$univ-polar} &,
      .#{$univ-litterature} &,
      .#{$univ-romance} & {
        padding-top : 45px;
        @media screen and (max-width : $mobile) {
          padding : 0 15px;
        }
      }
      h2 {
        color     : white;
        font-size : 30px;
        .#{$univ-imaginaire} &,
        .#{$univ-litterature} &,
        .#{$univ-romance} & {
          color     : $almost-noir;
          font-size : 50px;
        }
        .#{$univ-polar} & {
          font-size : 50px;
        }
      }
    }
    .page-taxonomy-term & {
      display : inline-block;
      margin  : 35px 0;
      @include bandeau-full-width($rouge);
      @media screen and (max-width : $mobile) {
        padding       : 0 15px;
        margin-bottom : 0px;
      }
    }
    .#{$univ-imaginaire} &,
    .#{$univ-polar} &,
    .#{$univ-litterature} &,
    .#{$univ-romance} & {
      &:before {
        display : none;
      }
    }
  }
  &.group-liens {
    .node-hw-livre.view-mode-full & {
      margin-top    : 25px;
      padding-right : 30px;
    }
  }
  &.group-infos-livre {
    text-align    : left;
    margin        : 50px 0;
    padding-right : 30px;
  }
  &.group-info-auteur {
    text-align    : left;
    margin-bottom : 15px;
  }
  &.group-bande-grise {
    @include bandeau-full-width($gris-clair);
  }
  &.group-info-user {
    float       : left;
    margin-left : 15px;
    .comment.view-mode-teaser_user & {
      margin-left : 0;
    }
  }
  &.group-profil,
  &.group-identifiants,
  &.group-informations,
  &.group-envie-de,
  &.group-newsletters,
  &.group-alertes,
  &.group-presse,
  &.group-res-soc {
    h3 {
      position   : relative;
      z-index    : 0;
      text-align : center;
      &:after {
        content    : "";
        width      : 100%;
        position   : absolute;
        left       : 0;
        height     : 1px;
        top        : 50%;
        background : $gris-moyen;
      }
      span {
        @extend .OngletActif;
        color          : $almost-noir;
        text-transform : uppercase;
        padding        : 0 33px;
        background     : #ffffff;
        position       : relative;
        z-index        : 1;
      }
    }
  }
  &.group-profil,
  &.group-identifiants,
  &.group-informations,
  &.group-envie-de,
  &.group-newsletters,
  &.group-res-soc {
    h3 {
      margin     : 75px 0;
      text-align : center;

      @media screen and (max-width : $tab) {
        margin : 50px 0;
      }
      @media screen and (max-width : $min-mobile) {
        margin : 35px 0;
      }
    }
  }
  &.group-presse,
  &.group-alertes {
    h3 {
      margin : 75px 0 10px;

      @media screen and (max-width : $tab) {
        margin : 50px 0 10px;
      }
      @media screen and (max-width : $min-mobile) {
        margin : 35px 0 10px;
      }
    }
    div {
      font-size : 24px;
    }
  }
  &.group-titres-auteur {
    display : inline-block;
  }
  &.group-gray-bg {
    //display  : inline-block;
    //position : relative;
    //@media screen and (max-width : $mobile) {
    //  margin-top : 25px;
    //}
    //&:before {
    //  content    : "";
    //  position   : absolute;
    //  top        : 0;
    //  bottom     : 0;
    //  left       : -50vw;
    //  right      : -50vw;
    //  background : $gris-clair;
    //  z-index    : -1;
    //}
  }
}

.field-group-htabs-wrapper {
  .horizontal-tabs {
    border : none;
    @media screen and (max-width : $mobile) {
      .node-hw-livre.view-mode-full & {
        border-color : $gris-sombre;
        border-style : solid;
        border-width : thin;
      }
    }
    .horizontal-tabs-list {
      background     : none;
      border         : none;
      border-bottom  : thin solid $gris-moyen;
      padding-bottom : 20px;
      cursor         : pointer;
      text-align     : center;
      li,
      .selected {
        background : none;
        display    : inline-block;
        float      : none;
        &:last-child {
          border : none;
        }
      }
      li:hover,
      li a:hover {
        background : none;
      }
      @media screen and (max-width : $mobile) {
        border     : thin solid #8f8f8f;
        padding    : 10px 15px;
        position   : relative;
        display    : inline-block;
        width      : 100%;
        text-align : center;
        .node-hw-livre.view-mode-full & {
          border-width : thin;
        }
        li {
          border  : none;
          display : none;
          &.selected {
            display : block;
          }
        }
        &:after {
          content  : "";
          @include icone('../images/icones/arrow-down.png', 30px, 15px);
          position : absolute;
          top      : 25px;
          right    : 15px;
          display  : inline-block;
          .node-hw-livre.view-mode-full & {
            top : 15px;
          }
        }
        &.no-arrow {
          &:after {
            display : none;
          }
        }
      }
      @media screen and (max-width : $min-mobile) {
        li:after {
          right : 5px;
          @include icone('../images/icones/arrow-down.png', 24px, 12px);
        }
      }
    }
    .horizontal-tab-button a {
      color   : $almost-noir;
      @extend .OngletInactif;
      padding : 0 20px;
      @media screen and (max-width : $tab) {
        font-size : 20px;
      }
      @media screen and (max-width : $mobile) {
        padding : 0.5em 0;
      }
      strong {
        font-weight : 300;
      }
      .selected & {
        @extend .OngletActif;
      }
    }
    .horizontal-tab-button.selected a {
      padding : 0 20px;
      @media screen and (max-width : $mobile) {
        padding : 0.5em 0;
      }
      strong {
        font-weight : 700;
      }
    }
    .horizontal-tabs-panes {
      background : none;
      @media screen and (max-width : $mobile) {
        .node-hw-livre.view-mode-full & {
          padding : 0 15px;
        }
      }
    }
    .horizontal-tabs-pane {
      background : none;
      border     : none;
      box-shadow : none;
      padding    : 0;
      @media screen and (max-width : $min-mobile) {
        margin-bottom : 0;
      }
    }
    .panel-body {
      padding : 0;
    }
  }
  &.group-details-promotions {
    margin-top : 45px;
    .horizontal-tabs-list {
      text-align : left;
      padding    : 0;
      border     : none;
      @media screen and (max-width : $mobile) {
        border-bottom : thin solid $gris-sombre;
      }
      a,
      .selected a {
        padding   : 0 9px 15px 9px;
        font-size : 20px;
        @media screen and (max-width : $desk-inter) {
          font-size : 17px;
        }
        @media screen and (max-width : $tab) {
          font-size : 14px;
        }
        @media screen and (max-width : $mobile) {
          padding   : 15px;
          font-size : 20px;
        }
        @media screen and (max-width : $min-mobile) {
          font-size  : 16px;
          text-align : left;
        }
      }
      a {
        border : none;
      }
      li,
      li:last-child {
        padding       : 0;
        border-bottom : thin solid #cccccc;
        @media screen and (max-width : $mobile) {
          border : none;
          width  : auto;
        }
      }
      li:first-child a {
        padding-left : 0;
        @media screen and (max-width : $mobile) {
          padding : 15px;
        }
      }
      .selected {
        padding       : 0;
        border-bottom : none;
      }
    }
    .group-details {
      .node-hw-livre.view-mode-full & {
        margin : 15px 0;
      }
    }
  }
  &.group-lies {
    .view-mode-full & {
      margin : 45px 0;
      .horizontal-tabs {
        border-width : 0;
      }
      .horizontal-tabs-list {
        margin-bottom : 30px;
        border-width  : thin;
        text-align    : center;
      }
    }
    .node-hw-livre.view-mode-full & {
      padding : 0 15px;
    }
  }
  &.group-tab-elt-lie {
    display    : inline-block;
    margin-top : 25px;
    width      : 100%;
  }
}

.field-name-field-date {
  div {
    font-size : 0;
  }
  .date-display-start {
    &:before {
      content : 'Du ';
    }
  }
  .date-display-end {
    &:before {
      content     : 'au ';
      margin-left : 5px;
    }
  }
  span {
    @extend .TexteCourant;
    color          : $blanc;
    text-transform : uppercase;
    font-size      : 20px;
    font-weight    : 200;
  }
  .view-mode-teaser_home &,
  .view-mode-teaser &,
  .view-mode-recherche &,
  .node-hw-livre.view-mode-teaser_livre & {
    padding       : 20px 5px;
    background    : $gris-clair;
    margin-bottom : 30px;
    span {
      color          : $rouge;
      font-size      : 16px;
      letter-spacing : 3px;
      font-weight    : 300;
    }
    @media screen and (max-width : $mobile) {
      text-align    : center;
      margin-bottom : 15px;
      padding       : 14px 10px;
    }
  }
  .field-name-bloc-agenda-livre & {
    background : #ffffff;
  }
  .node-jeux.view-mode-full & {
    span {
      text-transform : lowercase;
    }
  }
  .view-mode-recherche & {
    margin-bottom : 0;
  }
  .node-jeux.view-mode-teaser & {
    background : transparent;
    padding    : 0;
    text-align : left;
    margin     : 0 0 5px;
    span {
      color          : $almost-noir;
      font-size      : 15px;
      letter-spacing : normal;
    }

  }
}

.field-name-content-name,
.field-name-vocab-name {
  text-align     : left;
  color          : $almost-noir;
  @extend .SousTitre;
  text-transform : uppercase;
  letter-spacing : 5.6px;
  margin-bottom  : 20px;
  p {
    text-align : center;
  }
  @media screen and (max-width : $mobile) {
    letter-spacing : 3.7px;
    p {
      text-align : left;
    }
  }
  .view-mode-recherche & {
    p {
      font-size      : 26px;
      font-weight    : 700;
      letter-spacing : normal;
      text-align     : center;
    }
  }
  .node-article.view-mode-teaser_home &,
  .node-coup-coeur-libraire.view-mode-teaser_home & {
    text-align : center;
  }
}

.field-name-author {
  .node-coup-coeur-libraire.view-mode-teaser_home & {
    text-align    : left;
    margin-bottom : 20px;
    @extend .SousTitre;
  }
}

.field-name-field-libraire {
  .node-coup-coeur-libraire.view-mode-teaser_home &,
  .node-coup-coeur-libraire.view-mode-teaser &,
  .node-coup-coeur-libraire.view-mode-teaser_livre & {
    text-align  : left;
    @extend .TexteCourant;
    font-weight : 200;
  }
}

.field-name-field-chapo {
  text-align : left;
  .view-mode-full & {
    display : inline-block;
    width   : 100%;
    @include rem(margin, 50px 0 35px);
    p,
    a {
      color       : $almost-noir;
      @extend .TexteCourant;
      font-size   : 25px;
      font-weight : 700;
    }
    a {
      text-decoration : underline;
    }
    @media screen and (max-width : $mobile) {
      @include rem(margin, 35px 0 25px);
    }
  }
  .view-mode-teaser &,
  .view-mode-teaser_home & {
    text-align : left;
    margin     : 0;
  }
  .view-mode-teaser_home & {
    margin-top : 20px;
  }
  .field-collection-item-field-livre-selection.view-mode-full & {
    margin     : 0;
    text-align : left;
    p {
      @extend .TexteCourant;
      font-weight : 400;
    }
  }
  .view-mode-recherche & {
    margin-bottom : 15px;
  }
}

.field-name-social-share {
  margin-top : 45px;
  display    : inline-block;
  float      : right;
  .node-hw-livre.view-mode-full & {
    float : left;
    clear : both;
    @media screen and (max-width : $mobile) {
      float : none;
    }
  }
  .node-hw-intervenant.view-mode-full & {
    float      : none;
    width      : 100%;
    text-align : center;
  }
  .node-hw-livre.view-mode-full & {
    margin : 15px 0 45px;
  }
  @media screen and (max-width : $mobile) {
    margin-top : 15px;
    width      : 100%;
    text-align : center;
    float      : none;
  }
  .field-label {
    @extend .Mentions;
    color          : $almost-noir;
    text-transform : uppercase;
    letter-spacing : 2.6px;
    display        : inline-block;
    vertical-align : bottom;
    position       : relative;
    line-height    : 25px;
    .node-hw-intervenant.view-mode-full & {
      float : none;
    }
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
  }
  .field-items {
    .node-hw-intervenant.view-mode-full & {
      float   : none;
      display : inline-block;
    }
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
  }
  .rrssb {
    text-align : right;
    padding    : 0 !important;
    .page-user & {
      text-align : center;
      .rrssb-buttons {
        list-style : none;
        display    : inline-block;
        li {
          float : left;
        }
      }
    }
    .node-hw-livre.view-mode-full &,
    .node-hw-intervenant.view-mode-full & {
      text-align : left;
      @media screen and (max-width : $mobile) {
        text-align : center;
      }
    }
    @media screen and (max-width : $mobile) {
      text-align : center;
    }
  }
  .rrssb-buttons {
    padding : 0 !important;
    width   : auto !important;
  }
  .rrssb-buttons li {
    width : 40px;
    a {
      background : none;

      .page-user & {
        height : 20px;
      }
      &:hover {
        background : none;
      }
    }
    .rrssb-icon {
      background : none;
      @extend .fa;
      color      : $almost-noir;
      font-size  : 20px;
      top        : 0;
      left       : 0;
    }
    .rrssb-text {
      font-size : 0;
    }
  }

  .rrssb-facebook {
    .rrssb-icon {
      @extend .fa-facebook;
    }
  }
  .rrssb-twitter {
    .rrssb-icon {
      @extend .fa-twitter;
    }
  }
  .rrssb-pinterest {
    .rrssb-icon {
      @extend .fa-pinterest-p;
    }
  }
  .rrssb-email {
    .rrssb-icon {
      @extend .fa-envelope-o;
    }
  }
  .comment.view-mode-teaser_user & {
    margin-top : 5px;
    width      : 100%;
    .rrssb {
      text-align : center;
    }
  }
  .view-mode-liste_slick & {
    display : none;
  }
  .page-user & {
    display    : inline-block;
    width      : 100%;
    margin-top : 15px;
  }
}

.field-name-hw-intervenants {
  .view-mode-liste_slick & {
    margin-top : 5px;
    a {
      color       : $almost-noir;
      @extend .SousTitre;
      font-weight : 200;
    }
  }
  .view-mode-teaser_home & {
    text-align : left;
    a {
      font-size   : 15px;
      font-weight : 700;
    }
  }
  .view-mode-recherche &,
  .view-mode-teaser &,
  .view-mode-teaser_livre &,
  .field-collection-item-field-livre-selection &,
  .view-mode-livre_selection_themat &,
  .node-hw-livre.view-mode-teaser_livre & {
    text-align    : left;
    margin-bottom : 5px;
    font-size     : 15px;
    .field-item {
      display : inline-block;
      &:before {
        content : ', ';
      }
      &:first-child {
        &:before {
          content : '';
        }
      }
    }
    a {
      color       : $almost-noir;
      font-weight : 700;
      font-size   : 15px;
    }
  }
  .#{$univ-polar} & {
    a {
      color : $blanc;
    }
  }
  .field-name-hw-interventions & {
    text-align   : left;
    display      : inline-block;
    margin-right : 5px;
    a,
    p {
      color          : $almost-noir;
      @extend .TitreRubr;
      letter-spacing : 6.3px;
      font-size      : 18px;
    }
    .role-intervenant {
      text-transform : lowercase;
    }
  }
}

.field-name-field-texte-homepage {
  .vocabulary-hw-themes.view-mode-teaser_home & {
    margin-top : 25px;
    text-align : center;
    div, p, a {
      color          : $almost-noir;
      font-size      : 14px;
      font-weight    : 400;
      text-transform : uppercase;
      letter-spacing : 1.68px;
    }
  }
}

.field-name-hw-livre-themes,
.field-name-hw-livre-collections {
  .view-mode-recherche &,
  .node-hw-livre.view-mode-teaser_livre & {
    display        : inline-block;
    vertical-align : middle;
    margin-left    : 5px;
    .field-item {
      float       : left;
      font-size   : 14px;
      color       : $gris-sombre;
      font-weight : 200;
      &:before {
        content     : ',\00a0';
        white-space : normal;
      }
      &:first-child {
        &:before {
          content      : '-';
          margin-right : 5px;
        }
      }
    }
    a {
      color       : $gris-sombre;
      font-weight : 200;
      font-size   : 14px;
    }
  }
  .node-hw-livre.view-mode-teaser & {
    margin-bottom : 5px;
    float         : left;
    line-heigght  : 15px;
    a {
      color       : $gris-sombre;
      font-size   : 15px;
      font-weight : 200;
      &:hover {
        text-decoration : underline;
      }
    }
  }
  &.no-collect {
    display : none;
  }
}

.field-name-hw-presentation-editoriale {
  .view-mode-recherche & {
    text-align    : left;
    margin-bottom : 15px;
    font-size     : initial;
  }
  .view-mode-teaser & {
    text-align : left;
    clear      : both;
  }
  .node-hw-livre.view-mode-full & {
    @extend .TexteCourant;
    color         : $almost-noir;
    line-height   : 20px;
    padding       : 30px 0;
    margin-bottom : 30px;
    text-align    : left;
    &:before {
      left : 25%;
    }
    .field-item div {
      text-align : left !important;
    }
    .field-item,
    .field-item div,
    .field-item p,
    .field-item a,
    .field-item span {
      font-size : 16px;

      @media screen and (max-width : $mobile) {
        font-size : 14px;
      }
    }
  }
  .view-mode-livre_selection_themat & {
    font-weight : 400;
    line-height : 20px;
    font-size   : 15px;
    text-align  : left;
    color       : $almost-noir;
  }
}

.field-name-hw-intervenant-biographie {
  .view-mode-recherche & {
    text-align    : left;
    margin-bottom : 15px;
    font-size     : initial;
  }
  .view-mode-teaser & {
    text-align : left;
  }
  .view-mode-listes & {
    text-align : left;
    margin     : 15px 0;
    p {
      font-size : 15px;
      color     : $almost-noir;
    }
  }
  .node-hw-intervenant.view-mode-full & {
    color         : $almost-noir;
    @extend .TexteCourant;
    text-align    : left;
    margin-bottom : 30px;
  }
}

.taxonomy-term-description {
  .taxonomy-term.view-mode-recherche.#{$univ-polar} & {
    p {
      color : white;
    }
  }
  .view-mode-full & {
    max-width  : 890px;
    margin     : 15px auto;
    text-align : center;
    p {
      .page-taxonomy-term & {
        color     : $blanc;
        @extend .TexteCourant;
        font-size : 20px;
      }
      a {
        color           : $blanc;
        @extend .TexteCourant;
        font-size       : 20px;
        text-decoration : underline;
      }
      .#{$univ-imaginaire} &,
      .#{$univ-litterature} &,
      .#{$univ-romance} & {
        color : $almost-noir;
        a {
          color : $almost-noir;
        }
      }
      .#{$univ-imaginaire} &,
      .#{$univ-polar} &,
      .#{$univ-litterature} &,
      .#{$univ-romance} & {
        font-size : 15px;
        a {
          text-decoration : none;
          font-weight     : 600;
          font-size       : 15px;
        }
      }
    }
  }
  .view-mode-accroche & {
    text-align : left;
    p a {
      color     : $almost-noir;
      font-size : 15px;
    }
  }
}

.field-name-bloc-thematique {
  &:before {
    .#{$univ-imaginaire} &,
    .#{$univ-polar} &,
    .#{$univ-litterature} &,
    .#{$univ-romance} & {
      content : "";
    }
    position   : relative;
    width      : 100%;
    margin     : 0 auto;
    height     : 1px;
    background : $gris-sombre;
    display    : block;
    &.#{$univ-polar} {
      background : $blanc;
    }
  }
}

.field-name-field-image-fond {
  position : absolute;
  width    : 100%;
  top      : 0;
  left     : 0;
  z-index  : -1;
}

.field-name-lire-plus {
  .node-promo-en-cours.view-mode-recherche & {
    cursor  : pointer;
    display : inline-block;
    span {
      margin-right : 5px;
    }
    &:hover {
      span {
        color : $rouge;
      }
      .arrow-down-circle {
        background   : $rouge;
        border-color : transparent;
        .arrow-down {
          border-color : $blanc;
        }
      }
    }
  }
}

.field-name-field-lien,
.field-name-field-pdf,
.field-name-hw-fichier-contenu {
  .view-mode-full & {
    @include cartouche(14, 400);
    text-align : left;
    margin     : 25px 0;
    img {
      display : none;
    }
    a {
      padding : 12px 25px;
      &:before {
        content      : '';
        @include icone('../images/icones/book-b.png', 20px, 15px);
        display      : inline-block;
        margin-right : 15px;
      }
      &:hover {
        &:before {
          background-image : url('../images/icones/book-w.png');
        }
      }
    }
  }
  .node-contenu-pedagogique.node-promoted &,
  .paragraphs-item-contenus-complementaires & {
    margin : 0;
    a {
      @media screen and (max-width : $mobile) {
        padding        : 10px 20px;
        font-size      : 13px;
        letter-spacing : normal;
      }
    }
    .file {
      img {
        display : none;
      }
    }
  }
}

.field-collection-container {
  .view-mode-full & {
    border : none;
    margin : 0;
  }
}

.field-name-field-livre-selection,
.field-name-liste-selection-thematique {
  .view-mode-full & {
    .field-label {
      text-align     : left;
      font-size      : 22px;
      font-weight    : 800;
      color          : $almost-noir;
      text-transform : uppercase;
    }
    .field-item {
      margin  : 0;
      padding : 0;
    }
    .field-collection-view,
    .field-collection-item-field-livre-selection {
      border : none;
      @media screen and (max-width : $min-mobile) {
        padding : 15px;
      }
    }
    .col-sm-3,
    .col-sm-9 {
      vertical-align : top;
    }
  }
}

.field-name-date_parution {
  .field-collection-item-field-livre-selection.view-mode-full & {
    text-align  : left;
    font-size   : 15px;
    font-weight : 200;
    color       : $gris-sombre;
    margin      : 5px 0;
  }
}

.acheter {
  background    : $rouge;
  border-radius : 999px;
  display       : inline-block;
  padding       : 12px 25px;
  @include rem(margin, 25px 40px 25px 0);
  cursor        : pointer;
  @include transitions(transform 0.5s);
  &:hover {
    @include transform(scale(1.1));
  }
  @media screen and (max-width : $min-mobile) {
    margin-right : 0;
  }
  .view-mode-livre_selection_themat & {
    float : left;
  }
  .buybutton {
    background   : transparent;
    margin       : 0;
    padding      : 0;
    margin-right : 10px;
    border       : none;
    &:hover span {
      text-decoration : none;
    }
  }
  .buybutton span,
  .price {
    color          : $blanc;
    font-size      : 14px;
    text-transform : uppercase;
    letter-spacing : 1.68px;
    text-shadow    : none;
    @media screen and (max-width : $mobile) {
      font-size : 12px;
    }
  }
  .price {
    font-weight : 700;
    padding     : 3px 0 3px 10px;
    border-left : thin solid $blanc;
  }
}

.prevnext {
  @include bandeau-full-width($blanc);
  padding : 35px 0;
  display : inline-block;
  width   : 100%;
  .prev-content {
    float      : left;
    text-align : right;
  }
  .next-content {
    float      : right;
    text-align : left;
  }
  .prevnext-content {
    padding : 15px 0;
    @media screen and (max-width : $mobile) {
      margin     : 0 auto;
      float      : none;
      text-align : center;
    }
  }
  .prevnext-title {
    margin-top : 15px;
    a {
      color : $almost-noir;
      @extend .Lien;
    }
    &:after,
    &:before {
      content : '';
      display : inline-block;
    }
  }
  .next-title:after {
    @include icone('../images/icones/arrow-link.png', 20px, 14px);
    margin-left : 15px;
  }
  .prev-title:before {
    @include icone('../images/icones/arrow-link.png', 20px, 14px);
    margin-right : 15px;
    @include transform(rotate(180deg));
  }
}

.field-name-livre-etiquette-nouv-a-paraitre {
  #quicktabs-container-new_paraitre &,
  #quicktabs-container-new_top_paraitre &,
  #quicktabs-container-new_paraitre_univers &,
  .page-nouveautes &,
  .page-nouveautes-univers &,
  .page-a-paraitre &,
  .page-aparaitre-univers & {
    display : none;
  }
  #quicktabs-tabpage-new_top_paraitre-1 & {
    display : block;
  }
  position    : absolute;
  line-height : 24px;
  @include transform(rotate(-90deg));
  top         : 25px;
  left        : -54px;
  height      : 24px;
  width       : 104px;
  .bandeau {
    height         : 24px;
    width          : 104px;
    border-radius  : 999px 0 0 999px;
    text-transform : uppercase;
    display        : inline-block;
    font-size      : 12px;
    position       : relative;
    text-align     : center;
    &:before {
      content  : "";
      position : absolute;
      bottom   : -5px;
      right    : 0;
      width    : 15px;
      height   : 20px;
      @include transform(skewy(-33deg));
      z-index  : -1;
    }
    &.bandeau-nouveau {
      background : $rouge;
      color      : $blanc;
      &:before {
        background : #881b25;
      }
    }
    &.bandeau-aparaitre {
      background : $jaune;
      color      : #000000;
      &:before {
        background : #c8aa13;
      }
    }
  }
}

.field-name-bloc-recherche,
.field-name-bloc-top-recherche,
.field-name-qt-nouveaute-paraitre {
  .page-taxonomy-term & {
    display : none;
  }
  .#{$univ-imaginaire} &,
  .#{$univ-polar} &,
  .#{$univ-litterature} &,
  .#{$univ-romance} & {
    display : block;
  }
}

.field-name-hw-livre-themes,
.field-name-hw-livre-serie,
.field-name-hw-livre-collections {
  .node-hw-livre.view-mode-full & {
    .field-label {
      color          : $rouge;
      @extend .Mentions;
      font-weight    : 600;
      width          : 125px;
      letter-spacing : 2.94px;
      text-align     : left;
      line-height    : 20px;
      white-space    : nowrap;
      @media screen and (max-width : $mobile) {
        width : 50%;
      }
    }
    .field-items {
      @media screen and (max-width : $mobile) {
        width      : 50%;
        text-align : left;
      }
    }
    .field-item {
      display      : inline-block;
      margin-right : 10px;
      float        : left;
      clear        : both;
      @media screen and (max-width : $mobile) {
        margin-right : 0;
        text-align   : left;
      }
      a {
        color       : $almost-noir;
        @extend .TexteCourant;
        line-height : 20px;
      }
    }
  }
}

.field-name-hw-livre-nb-pages,
.field-name-hw-livre-date-parution,
.field-name-hw-livre-langue,
.field-name-hw-livre-ean,
.field-name-hw-livre-editeur-origine {
  .node-hw-livre.view-mode-full & {
    div, span {
      color          : $gris-sombre;
      @extend .Mentions;
      line-height    : 26px;
      text-transform : none;
    }
    .field-item {
      pointer-events  : none;
      text-decoration : none;
      color           : inherit;
    }
  }
}

.field-name-hw-livre-nb-pages {
  .node-hw-livre.view-mode-full & {
    .field-item {
      display : inline-block;
      &:after {
        content : ' pages';
      }
    }
  }
}

/*.field-name-bloc-serie,
.field-name-bloc-collection {
  margin-top : 50px;
}*/

.field-name-field-podcast {
  .view-mode-full & {
    margin : 50px 0;
    @media screen and (max-width : $mobile) {
      margin : 20px 0;
    }
    .jp-audio {
      margin : 0 auto;
    }
    .jp-interface,
    .jp-playlist {
      border     : none;
      background : transparent;
    }
  }
}

.field-name-hw-intervenant-sites {
  .node-hw-intervenant.view-mode-full & {
    display       : inline-block;
    text-align    : left;
    margin-bottom : 30px;
    .field-item {
      @include cartouche(12, 200);
      display      : inline-block;
      margin-right : 30px;
      @media screen and (max-width : $mobile) {
        margin-right : 0;
      }
    }
    @media screen and (max-width : $mobile) {
      text-align : center;
    }
  }
}

.field-name-site-auteur,
.field-name-hw-livre-site-article {
  .node-hw-livre.view-mode-full & {
    display    : inline-block;
    text-align : left;
    margin     : 30px 30px 30px 0;
    float      : left;
    .field-content,
    .field-item {
      @include cartouche(12, 200);
      display : inline-block;
      margin  : 0;
      @media screen and (max-width : $mobile) {
        margin-right : 0;
      }
    }
  }
}

.field-name-hw-livre-couverture {
  img {
    border : thin solid #d9dadb;
  }
}

.field-name-hw-prix-litt,
.field-name-hw-oeuvres-audiovisuelles,
.field-name-bloc-prix-litteraire-auteur {
  .node-hw-livre &,
  .node-hw-intervenant & {
    .field-label,
    .block-title {
      font-size      : 13px;
      font-weight    : 500;
      line-height    : 20px;
      text-transform : uppercase;
      letter-spacing : 2.94px;
      color          : $almost-noir;
      display        : inline-block;
      border-bottom  : thin solid $almost-noir;
      margin-bottom  : 20px;
      margin-top     : 0;

      a {
        color       : $almost-noir;
        font-size   : 13px;
        font-weight : 500;
        line-height : 20px;

        &:hover {
          font-weight : bold;
        }
      }
    }
    .field-item {
      font-size     : 15px;
      color         : $gris-sombre;
      text-align    : left;
      margin-bottom : 10px;

      .col-sm-12 {
        text-align : left;

        .field {
          display : inline-block;

          .field-item {
            margin-bottom : 0;
            color         : $gris-sombre;

            a,
            span {
              color : $gris-sombre;
            }
          }

          &.field-name-hw-prix-litt-date,
          &.field-name-hw-debut-audiovis {
            .field-item {
              &:before {
                content : '\00a0 (';
              }
              &:after {
                content : ')';
              }
            }
          }
        }
      }
    }
  }

  .node-hw-livre.view-mode-full &,
  .node-hw-intervenant.view-mode-full & {
    text-align    : left;
    padding       : 30px 0 30px 65px;
    border-top    : thin solid $gris-moyen;
    border-bottom : thin solid $gris-moyen;
    position      : relative;
    margin        : 0;
    display       : inline-block;
    width         : 100%;
    &:before {
      content  : "";
      @include icone('../images/icones/prix-lit.png', 45px, 45px);
      position : absolute;
      left     : 0;
      top      : 15px;
    }
  }

  .node-hw-livre.view-mode-teaser_livre & {
    padding : 20px 0;

    .field-item {
      font-size     : 15px;
      color         : $almost-noir;
      text-align    : left;
      margin-bottom : 10px;

      .col-sm-12 {
        text-align : left;

        .field {
          display : inline-block;

          .field-item {
            margin-bottom : 0;
            color         : $almost-noir;

            a,
            span {
              color : $almost-noir;
            }
          }
        }
      }
    }
  }
}

.field-name-hw-prix-litt {
  .page-adaptation-cinema & {
    display : none;
  }
}

.field-name-hw-oeuvres-audiovisuelles {
  .node-hw-livre.view-mode-full & {
    margin-top : -1px;

    &:before {
      content  : "";
      @include icone('../images/icones/adapte-cine.png', 45px, 45px);
      position : absolute;
      left     : 0;
      top      : 15px;
    }

    .field {
      display : inline !important;

      .field-items {
        display : inline;

        .field-item,
        .field-item a,
        .field-item span {
          display : inline;
          color   : $almost-noir !important;
        }
      }
    }
  }
  .page-prix-litteraires & {
    display : none;
  }
}

.field-name-hw-audio-contenu,
.download-couv_hd,
.download-communique-presse,
.flag-outer-pile-lire,
.view-mode-paragraph_lire_un_extrait .field-name-hw-url-contenu .field-item{
  .node-hw-livre.view-mode-full & {
    padding       : 5px 0;
    line-height   : 20px;
    display       : inline-block;
    text-align    : left;
    font-size     : 0;
    width         : 100%;
    border-bottom : thin solid $gris-moyen;
    .field-item {
      padding : 0 15px;
    }
    img {
      display : none;
    }
    .file:before {
      content : "";
      @include icone('../images/icones/extrait-audio.png', 10px, 13px);
      display : inline-block;
    }
    a {
      color          : $gris-sombre;
      font-size      : 13px;
      font-weight    : 200;
      text-transform : uppercase;
      padding-left   : 20px;
      span {
        color     : inherit;
        font-size : inherit;
      }
    }
    iframe {
      //height : 36.5px;
      border : none;
    }
  }
  .node-hw-livre.view-mode-liste_slick & {
    display : none;
    .view-pal & {
      display : block;
    }
  }
}

.field-name-hw-audio-contenu {
  .node-hw-livre.view-mode-full & {
    .field-label {
      color          : $gris-sombre;
      font-size      : 13px;
      font-weight    : 200;
      text-transform : uppercase;
      padding-left   : 20px;
      display        : inline-block;
      cursor         : pointer;
    }
    &:before {
      content     : "";
      @include icone('../images/icones/extrait-audio.png', 10px, 13px);
      display     : inline-block;
      margin-left : 5px;
    }
    .field-items {
      display : none;
    }
  }
}

.download-couv_hd,
.download-communique-presse {
  .node-hw-livre.view-mode-full & {
    &:before {
      content  : "";
      @include icone('../images/icones/download.png', 19px, 19px);
      display  : inline-block;
      position : relative;
      top      : 5px;
    }
  }
}

.flag-outer-pile-lire {
  .node-hw-livre.view-mode-full &,
  .node-hw-livre.view-mode-livre_selection_themat & {
    &:before {
      content             : "";
      @include icone('../images/icones/pal.png', 20px, 24px);
      display             : inline-block;
      position            : relative;
      top                 : 5px;
      background-position : center center;
      opacity             : 0.5;
    }
  }
  .node-hw-livre.view-mode-livre_selection_themat & {
    text-align : left;
    margin-top : 15px;
    span {
      text-transform : uppercase;
      color          : #616161;
      font-size      : 13px;
      font-weight    : 400;
      line-height    : 40px;
    }
  }
}

.flag-outer-alerte {
  .flag-message {
    padding        : 10px;
    display        : inline-block;
    vertical-align : middle;
  }
  .node-hw-intervenant.view-mode-full & {
    display        : inline-block;
    vertical-align : middle;
    margin-bottom  : 22px;
    a {
      display        : inline-block;
      border         : 1px solid $almost-noir;
      font-size      : 12px;
      padding        : 6px 24px;
      border-radius  : 20px;
      color          : $almost-noir;
      text-transform : uppercase;
      margin-top     : 1.25rem;
      margin-right   : 30px;
      @media screen and (max-width : $mobile) {
        margin-right : 0;
      }
      &:hover {
        background      : #ce3b3a;
        color           : #ffffff;
        border-color    : transparent;
        text-decoration : none;
      }

      span {
        font-size : 12px;

        &:hover {
          color : #ffffff;
        }
      }
    }
  }
}

.flag-outer-abus {
  margin-top : 20px;
  float      : right;
  .comment.view-mode-full & {
    text-decoration : underline;
  }
  .comment.view-mode-teaser_user & {
    span {
      text-decoration : underline;
      color           : $almost-noir;
      font-size       : 14px;
      opacity         : 0.7;
    }
  }
}

.field-name-hw-livre-adresse-internet {
  .node-hw-livre.view-mode-full & {
    .field-item {
      display : inline-block;
      margin  : 15px 15px 0 0;
      @media screen and (max-width : $mobile) {
        width  : 100%;
        margin : 5px 0;
      }
    }
    @include cartouche(12, 400);
    text-align : left;
    @media screen and (max-width : $mobile) {
      text-align : center;
    }
  }
}

.field-coups-coeur-libraires,
.field-avis-lecteurs-ancre {
  .node-hw-livre.view-mode-full & {
    text-align    : left;
    float         : left;
    width         : 50%;
    margin-bottom : 30px;
    @media screen and (max-width : $mobile) {
      text-align : center;
      width      : 100%;
    }

    a {
      color          : $almost-noir;
      font-size      : 13px;
      font-weight    : 400;
      text-transform : uppercase;
      letter-spacing : 2.94px;
      display        : inline-block;
      width          : 90%;

      &:hover {
        font-weight : bold;
        color       : $rouge;
      }
    }
  }
}

.field-coups-coeur-libraires {
  .node-hw-livre.view-mode-full & {
    .field-item {
      padding-left : 40px;
      position     : relative;
      @media screen and (max-width : $mobile) {
        padding : 0;
      }
      &:before {
        content  : '';
        @include icone('../images/icones/coeur.png', 28px, 26px);
        display  : inline-block;
        left     : 0;
        position : absolute;
        @media screen and (max-width : $mobile) {
          position            : relative;
          width               : 100%;
          background-position : center;
          margin-bottom       : 5px;
        }
      }

      a {
        width : 175px;
      }
    }
  }
}

.field-name-hw-interventions {
  .node-hw-livre.view-mode-full & {
    border : none;
    width  : 100%;
    .col-sm-12 {
      text-align : left;
      @media screen and (max-width : $mobile) {
        text-align : center;
      }
    }
  }
}

.field-name-hw-intervenant-photos {
  .node-hw-intervenant.view-mode-teaser & {
    @media screen and (max-width : $mobile) {
      margin-bottom : 15px;
    }
  }
}

.field-name-field-visuel-collection {
  .taxonomy-term.view-mode-full & {
    @media screen and (max-width : $mobile) {
      padding : 0 15px;
    }
  }
}

.field-name-hw-interventions {
  .node-hw-livre.view-mode-full & {
    margin : 15px 0 25px;
  }
}

.field-name-bloc-agenda-livre,
.field-name-bloc-actualites-livre,
.field-name-bloc-coups-de-coeur-livre,
.field-name-bloc-jeux-livre,
.field-name-bloc-agenda-auteur,
.field-name-bloc-actualites-auteur,
.field-name-bloc-coups-de-coeur-auteur,
.field-name-bloc-jeux-auteur {
  .node-hw-livre.view-mode-full &,
  .node-hw-intervenant.view-mode-full & {
    max-width : 1080px;
    margin    : 0 auto 50px;
    .block-title-wrapper {
      text-align : center;
    }
  }
}

.field-name-bloc-coups-de-coeur-livre,
.field-name-bloc-coups-de-coeur-auteur {
  .node-hw-livre.view-mode-full &,
  .node-hw-intervenant.view-mode-full & {
    .content {
      margin-top : 30px;
    }
  }
}

.field-name-bloc-videos-livre {
  .node-hw-livre.view-mode-full & {
    margin     : 35px 0;
    @include bandeau-full-width($gris-clair);
    padding    : 25px 0;
    text-align : center;
  }
}

.field-name-bloc-autopromo {
  .node-hw-intervenant.view-mode-full & {
    margin  : 35px 0 0;
    @include bandeau-full-width($blanc);
    padding : 25px 0 0;
  }
}

.comment-wrapper {
  background : $orange;
  padding    : 50px 75px;
  display    : inline-block;
  width      : 100%;
  max-width  : 1080px;
  @media screen and (max-width : $mobile) {
    padding : 30px 20px;
  }
  .title {
    color          : #ffffff;
    text-transform : uppercase;
    font-size      : 25px;
    font-weight    : 200;
    margin         : 0;
  }
  .comments-list-wrapper {
    margin : 25px 0 50px;
  }
  .comment-load {
    text-align : center;
  }
  .comment-form {
    padding : 0 25px;
    float   : left;
    @media screen and (max-width : $mobile) {
      padding : 0;
    }
    &.title {
      text-align : right;
      width      : 45%;
      @media screen and (max-width : $mobile) {
        width         : 100%;
        text-align    : center;
        margin-bottom : 15px;
      }
      strong {
        font-size   : 40px;
        font-weight : 800;
      }
    }
    &#comment-form {
      width      : 55%;
      text-align : left;
      @media screen and (max-width : $mobile) {
        width : 100%;
      }
      #edit-field-blog {
        display : none;
      }
    }
    .control-label {
      display : none;
    }
  }
}

.comment.view-mode-full {
  background    : #ffffff;
  margin-bottom : 25px;
  padding       : 25px 35px;
  @media screen and (max-width : $mobile) {
    padding : 15px;
  }
}

.links {
  .comment.view-mode-full &,
  .comment.view-mode-teaser_user & {
    clear : both;
    li {
      float : right;
      a {
        font-size     : 14px;
        line-height   : 40px;
        font-family   : "lft-etica", sans-serif;
        font-weight   : 400;
        padding-right : 47px;
        display       : inline-block;
        color         : $almost-noir;
        @media screen and (max-width : $mobile) {
          font-size     : 12px;
          line-height   : 21px;
          padding-right : 28px;
          font-weight   : normal;
        }
      }
      &.comment-delete a {
        background : url(../images/icones/delete.png) no-repeat right center;
        @media screen and (max-width : $mobile) {
          color      : $rouge;
          background : url(../images/icones/delete-small.png) no-repeat right center;
        }
      }
    }
  }
}

.form-control {
  .comment-form & {
    border        : none;
    box-shadow    : none;
    margin-bottom : 15px;
    &:hover,
    &:focus {
      box-shadow : none;
      border     : none;
    }
  }
}

.field-name-field-vote-commentaire {
  margin-bottom : 15px;
  .comment.view-mode-full & {
    display : inline-block;
  }
  .help-block {
    display : none;
  }
  .fivestar-widget-static {
    .star {
      background   : transparent;
      width        : 22px;
      height       : 20px;
      margin-right : 5px;
      float        : left;

      span {
        color             : transparent;
        background-repeat : no-repeat;
        background-size   : contain;
        display           : inline-block;
        width             : 100%;
        height            : 100%;
      }
      .on {
        background : url('../images/icones/star-b.png');
      }
      .off {
        background : url('../images/icones/star-g.png');
      }
    }
  }
  .fivestar-widget {
    margin-bottom : 25px;
    @media screen and (max-width : $mobile) {
      display : inline-block;
    }
    .star {
      width             : 26px;
      height            : 26px;
      margin-right      : 10px;
      background        : url('../images/icones/star-thin.png') no-repeat;
      background-repeat : no-repeat;
      background-size   : contain;
      &.hover,
      &.on {
        background-image : url('../images/icones/star-w.png');
      }
      a {
        font-size  : 0;
        background : transparent;
      }
    }
  }
}

.field-name-comment-body {
  text-align : center;
  width      : 100%;
  float      : left;
  margin-top : 15px;
  .field-item {
    text-align : left;
  }

  .comment-form & {
    textarea {
      border-radius : 20px;
      color         : $almost-noir;
      font-size     : 15px;
      padding       : 15px;
      margin-bottom : 25px;
    }
    .grippie {
      display : none;
    }
  }
  .view-mode-teaser_home & {
    font-size   : 15px;
    color       : $almost-noir;
    font-weight : 200;
  }
  .view-mode-teaser_user & {
    font-size     : 15px;
    line-height   : 25px;
    color         : $almost-noir;
    margin-bottom : 15px;
    transition    : height .5s;
    text-align    : left;
  }
  p {
    margin-bottom : 17.6px;
  }
}

.field-name-auteur-commentaire {
  a {
    font-size   : 15px;
    color       : $almost-noir;
    font-weight : 700;
  }
}

.field-name-role_user {
  color          : $almost-noir;
  font-size      : 13px;
  font-weight    : 300;
  letter-spacing : 2.6px;
  text-transform : uppercase;
}

#edit-author--2 {
  display : none;
}

.form-submit {
  .comment-form &,
  .comment-login & {
    background     : transparent;
    border         : thin solid #ffffff;
    border-radius  : 999px;
    color          : #ffffff;
    text-transform : uppercase;
    letter-spacing : 1.68px;
    font-size      : 14px;
    padding        : 15px 35px;
    &:hover {
      background : #ffffff;
      color      : $orange;
    }
    span {
      display : none;
    }
  }

  .comment-login & {
    float : right;
    @media screen and (max-width : $tab) {
      width : 50%;
    }
    @media screen and (max-width : $mobile) {
      float   : none;
      display : inline-block;
      width   : auto;
    }
  }
}

.comment-login {
  overflow : hidden;
  @media screen and (max-width : $mobile) {
    text-align : center;
  }
  .title.comment-form {
    width : auto;
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
  }
}

.username {
  font-weight : 700;
  color       : $almost-noir;
  font-size   : 15px;
  .comment.view-mode-teaser_home & {
    float : left;
    @media screen and (max-width : $mobile) {
      width   : 75%;
      padding : 0 15px;
    }
  }
}

.field-name-titre-livre-homepage {
  float : left;
  .view-mode-teaser_user & {
    margin-bottom : 5px;
    font-weight   : 700;
    color         : $almost-noir;
    font-size     : 15px;
  }
  a {
    font-weight : 700;
    color       : $almost-noir;
    font-size   : 15px;
    .view-mode-teaser_user & {
      text-decoration : underline;
    }
  }
  .comment.view-mode-teaser_home & {
    clear       : both;
    font-weight : 700;
    color       : $almost-noir;
    font-size   : 15px;
    @media screen and (max-width : $mobile) {
      float : none;
      clear : none;
    }
  }
}

.field-name-field-sobuzz {
  .node-jeux.view-mode-full & {
    iframe {
      width         : 100%;
      height        : 1250px;
      margin-bottom : 50px;
    }
  }
}

.field-name-field-date-de-naissance {
  .page-user & {
    fieldset {
      background : transparent;
      border     : none;
      width      : 50%;
      text-align : left;
      box-shadow : none;
    }
    legend {
      background  : transparent;
      border      : none;
      padding     : 0 20px;
      @extend .TexteCourant;
      color       : $almost-noir;
      font-weight : 400;
    }
    .panel-body {
      padding : 0 20px;
      .date-padding {
        padding : 0;
        .form-item {
          padding-right : 15px;
          select {
            width : 87px;
          }
          &.form-item-picture-delete,
          &.form-item-files-picture-upload {
            text-align : center;
          }
          input {
            text-align : center;
          }
        }
      }
    }
  }
}

.form-item-subject {
  .comment-form & {
    input {
      border-radius : 10px;
    }
  }
}

.field-name-ds-user-picture {
  float : left;
  img {
    width         : 82px;
    height        : 82px;
    border-radius : 50%;
  }
  .comment.view-mode-full & {
    margin-right : 15px;
  }
}

.above-top {
  p {
    font-size      : 30px;
    font-weight    : 200;
    text-transform : uppercase;
    color          : $almost-noir;
    line-height    : normal !important;
  }
}

.top-region {
  width      : 100%;
  margin     : 50px auto 0;
  max-width  : 920px;
  text-align : left;
  .icone-text {
    float         : left;
    width         : 50%;
    margin-bottom : 50px;
    @media screen and (max-width : $tab) {
      text-align    : center;
      margin-bottom : 0;
      height        : 250px;
    }
    img {
      float        : left;
      margin-right : 35px;
      @media screen and (max-width : $tab) {
        display : inline-block;
        float   : none;
        margin  : 0 0 15px;
      }
    }
    .texte-descriptif {
      float : left;
      @media screen and (max-width : $tab) {
        float : none;
      }
      p {
        color     : $almost-noir;
        font-size : 16px;
        margin    : 0;
        strong {
          font-size : 19px;
        }
      }
    }
  }
  .inscript-lecteur {
    .button-card {
      text-align : center;
      margin     : 50px 0;
      display    : inline-block;
      width      : 100%;
      a {
        text-transform : uppercase;
        color          : #ffffff;
        font-size      : 14px;
        letter-spacing : 1.68px;
        padding        : 12px 35px;
        background     : $orange;
        border-radius  : 999px;
        border         : thin solid $orange;
        display        : inline-block;
        &:hover {
          background : transparent;
          color      : $orange;
        }
      }
    }
  }
}

.bottom-region {
  margin-top     : 25px;
  position       : relative;
  padding-top    : 15px;
  font-size      : 0;
  text-align     : center;
  padding-bottom : 210px;
  margin-bottom  : -50px;

  .card-bottom {
    padding        : 75px 35px 50px;
    margin         : 12px 1%;
    background     : #ffffff;
    display        : inline-block;
    width          : 31%;
    max-width      : 353px;
    min-height     : 550px;
    box-shadow     : 0 0 12px rgba(0, 0, 0, 0.1);
    position       : relative;
    vertical-align : top;

    @media screen and (max-width : $tab) {
      width : 48%;
    }
    @media screen and (max-width : $mobile) {
      width          : 100%;
      margin         : 12px 0;
      min-height     : initial;
      padding-bottom : 100px;
    }
  }
  .title-card {
    text-align     : center;
    margin-bottom  : 50px;
    padding-bottom : 25px;
    position       : relative;
    &:after {
      content    : "";
      position   : absolute;
      width      : 25px;
      height     : 3px;
      background : $almost-noir;
      left       : calc(50% - 25px / 2);
      bottom     : 0;
    }
    p {
      letter-spacing : 4.8px;
      color          : $almost-noir;
      font-size      : 24px;
      text-transform : uppercase;
      strong {
        font-weight : 800;
      }
    }
  }
  .list-card {
    ul {
      padding-left : 20px;
    }
    li {
      margin     : 15px 0;
      font-size  : 15px;
      color      : $almost-noir;
      list-style : none;
      position   : relative;
      text-align : left;
      &:before {
        content       : "";
        display       : block;
        width         : 8px;
        height        : 15px;
        border-bottom : 3px solid $orange;
        border-right  : 3px solid $orange;
        @include transform(rotate(45deg));
        left          : -20px;
        position      : absolute;
        top           : 5px;
      }
    }
  }

  .button-card {
    text-align : center;
    bottom     : 50px;
    position   : absolute;
    left       : 0;
    right      : 0;
    a {
      text-transform : uppercase;
      color          : #ffffff;
      font-size      : 14px;
      letter-spacing : 1.68px;
      padding        : 12px 35px;
      background     : $orange;
      border-radius  : 999px;
      border         : thin solid $orange;
      display        : inline-block;
      &:hover {
        background : transparent;
        color      : $orange;
      }
    }
  }
  img {
    position        : absolute;
    top             : 0;
    left            : 50%;
    width           : 100vw;
    height          : 100%;
    object-fit      : cover;
    object-position : bottom;
    @include transform(translatex(-50%));
    z-index         : -1;
    @media screen and (max-width : $tab) {
      top    : auto;
      bottom : 0;
    }
  }
}

.field-name-image-livre-homepage {
  .comment.view-mode-teaser_home & {
    @media screen and (max-width : $mobile) {
      float        : left;
      width        : 25%;
      margin-right : 15px;
    }
  }
  .comment.view-mode-teaser_user & {
    img {
      border : thin solid $almost-blanc;
    }
  }
}

.field-name-auteur-livre {
  .comment.view-mode-teaser_user & {
    float       : left;
    color       : $almost-noir;
    font-size   : 15px;
    font-weight : 700;
    a {
      color           : $almost-noir;
      text-decoration : underline;
      font-size       : 15px;
      font-weight     : 700;
    }
  }
}

.flag-outer-pile-lire {
  .view-mode-liste_slick & {
    display : none;
  }
  .page-user & {
    display  : block;
    position : absolute;
    top      : 1px;
    right    : 1px;
    .flag-wrapper {
      position : initial;
    }
    a {
      position   : absolute;
      top        : 0;
      right      : 0;
      width      : 28px;
      height     : 28px;
      background : $rouge;
      font-size  : 0;
      &:before {
        content             : "";
        width               : 100%;
        height              : 100%;
        background          : url('../images/icones/close-w.png');
        background-repeat   : no-repeat;
        background-position : center;
        display             : inline-block;

      }
      .flag-inner {
        font-size : 0;
        cursor    : pointer;
      }
    }
  }
}

.flag-alerte {
  .page-user & {
    .unflag-action {
      @media screen and (max-width : $mobile) {
        &:hover {
          background : $rouge;
          border     : 1px solid $rouge;
          .flag-inner {
            background : url(../images/icones/close-w.png) no-repeat center;
          }
        }
      }
      .flag-inner {
        background : url(../images/icones/close.png) no-repeat center;
        width      : 100%;
        height     : 100%;
        display    : block;
        font-size  : 0;
        cursor     : pointer;
        @media screen and (max-width : $mobile) {
          display         : inline-block;
          vertical-align  : middle;
          width           : 13px;
          height          : 13px;
          line-height     : 13px;
          background-size : cover;
        }
      }
      height         : 21px;
      width          : 18px;
      margin         : 5px 0 5px 24px;
      display        : inline-block;
      vertical-align : middle;
      font-size      : 0;
      @media screen and (max-width : $mobile) {
        border         : 1px solid $almost-noir;
        position       : absolute;
        top            : 10px;
        right          : 0;
        width          : 28px;
        height         : 28px;
        border-radius  : 20px;
        display        : inline-block;
        vertical-align : middle;
        text-align     : center;
      }
    }
    .unflagged {
      display : none;
    }
    .flag-message {
      display   : inline-block;
      display   : inline-block;
      font-size : 14px;
      position  : relative;
      top       : auto;
    }
  }
}

.field-lien-post-avis {
  .view-mode-liste_slick & {
    display : none;
  }
  .page-user & {
    display    : inline-block;
    margin-top : 5px;
    a {
      font-size      : 14px;
      text-transform : uppercase;
      letter-spacing : 1.68px;
      color          : #ffffff;
      border-radius  : 999px;
      background     : $orange;
      padding        : 14px 25px;
      border         : thin solid $orange;
      white-space    : nowrap;
      display        : inline-block;
      &:hover {
        color      : $orange;
        background : transparent;
      }
    }
  }
}

.field-name-role-inteventions {
  text-align     : left;
  display        : inline-block;
  margin-right   : 5px;
  color          : $almost-noir;
  font-size      : 18px;
  letter-spacing : 6.3px;
}

.field-name-lien-avis-ancre {
  float      : left;
  margin-top : 5px;
  a {
    font-size       : 15px;
    color           : $almost-noir;
    font-weight     : 200;
    text-decoration : underline;
  }
}

.field-possede-contenu-pedagogique {
  margin-bottom : 5px;

  .view-mode-teaser & {
    display : none;
  }
  .page-user & {
    display : block;
  }
  .field-item {
    text-align : left;
    font-style : italic;
  }
}

.field-name-field-je-suis-blogueur,
.field-name-field-je-suis-journaliste,
.field-name-field-je-suis-enseignant {
  display : none;
}

.field-name-hw-url-contenu {
  iframe {
    display : none;
  }
  .view-mode-paragraph_contenu_pedagogique & {
    a {
      color         : $almost-noir;
      border        : thin solid $almost-noir;
      border-radius : 999px;
      padding       : 15px 25px;
      display       : inline-block;
      &:before {
        content         : "";
        background      : url('../images/icones/book-b.png');
        width           : 30px;
        height          : 23px;
        background-size : 100%;
        display         : inline-block;
        vertical-align  : middle;
        margin-right    : 5px;
      }
    }
  }

  .view-mode-paragraph_lire_un_extrait & {
    .field-item:before {
      content  : "";
      @include icone('../images/icones/lire-extrait-petit.png', 20px, 19px);
      display  : inline-block;
      position : relative;
      top      : 6px;
      opacity  : 0.5;
    }
  }
}

.field-name-popin-connexion-cont-pedagogique {
  background : #ffffff;
}

.group-web-site {
  .node-hw-livre.view-mode-full & {
    display : inline-block;
    clear   : both;
    float   : left;
  }
}
