.slick-dots {
  position      : absolute;
  bottom        : 0;
  width         : 100%;
  padding       : 0;
  list-style    : none;
  margin-bottom : 25px;
  text-align    : center;
  .view-video & {
    bottom : -66px;
  }
  .view-coups-de-coeur-libraires & {
    position       : relative;
    padding-bottom : 15px;
    margin-bottom  : 0;
    background     : $turquoise;
  }
  li {
    display       : inline-block;
    font-size     : 0;
    width         : 15px;
    height        : 15px;
    border-radius : 50%;
    background    : $blanc;
    cursor        : pointer;
    margin        : 0 15px 0 0;
    opacity       : .26;
    &.slick-active {
      opacity : 1;
    }
    .view-video &,
    .region-blockgroup-social-feeds & {
      background : $gris-moyen;
      opacity    : 1;
      &.slick-active {
        background : $almost-noir;
      }
      button {
        display : none;
      }
    }
  }
  .region-blockgroup-social-feeds & {
    position : relative;
  }
  .view-avis & {
    margin : 0;
    bottom : -45px;
    button {
      display : none;
    }
  }
  .view-livres &,
  .view-theme-slider-home & {
    position : relative;
    li {
      background : #000000;
      @media screen and (max-width : $mobile) {
        margin : 10px 10px 0 0;
      }
    }
  }
}

.slick__arrow {
  @media screen and (max-width : $tab) {
    text-align : center;
  }
  .slick-arrow {
    @media screen and (max-width : $tab) {
      position  : relative;
      transform : none;
      display   : inline-block;
      margin    : 15px 0;
    }
  }
  .slick-prev {
    @include icone('../images/icones/arrow-left.png', 23px, 53px);
    left : -27px;
  }
  .slick-next {
    @include icone('../images/icones/arrow-right.png', 23px, 53px);
    right : -27px;
  }
}

.slick {
  .slick-list {
    .block-quicktabs &,
    .view-livres.view-display-id-block_13 &,
    .group-lies & {
      padding : 25px 0 0;
    }
  }
  &.slick--view--coups-de-coeur-libraires {
    background : $turquoise;
  }
  &.slick--view--actualites {
    &.slick--optionset--dot-1 {
      img {
        width : 100%;
      }
    }
  }
  &.slick--optionset--5-to-2-arrows,
  &.slick--optionset--clone-of-5-to-2-arrows {
    position  : relative;
    max-width : 1070px;
    margin    : 0 auto;
  }
  &.slick--view--livres--block-16 .slick-track {
    margin: 0;
  }
}
