.form-group {
  .node.view-mode-full & {
    margin-top : 40px;
    text-align : right;
    @media screen and (max-width : $mobile) {
      text-align : center;
      &.form-actions {
        text-align : right;
      }
    }
  }
  .comment.view-mode-full &,
  #comment-form & {
    margin : 0;
    .form-item-subject {
      margin-bottom : 25px;
    }
    #edit-comment-body-und-0-format {
      display : none;
    }
  }
}

#googleplus,
#print {
  display : none;
}

#facebook {

}

#twitter {

}

#pinterest {

}

#mail {

}

.facetapi-select {
  border-radius : 0;
  border-color  : $almost-noir;
  color         : $almost-noir;
  @extend .TexteCourant;
  height        : 38px;
  width         : 254px;
  &:focus {
    border-color : $almost-noir;
    box-shadow   : none;
  }
  .#{$univ-imaginaire} & {
    background : $imaginaire;
  }
}

.webform-client-form {
  .node-webform.view-mode-full & {
    max-width : 740px;
    margin    : 0 auto;
    .form-group {
      text-align : left;
      margin-top : 20px;
      .form-control,
      .form-select {
        width         : 100%;
        height        : 58px;
        border-radius : 0;
        border        : thin solid #9a9999;
        padding       : 0 15px;
        &.form-textarea {
          height  : 260px;
          padding : 15px;
        }
      }
      .form-control,
      .form-select,
      .form-textarea {
        &:focus {
          box-shadow : none;
        }
      }
      .form-select {
        @include appearance(none);
        background      : transparent url('../images/icones/arrow-down.png') no-repeat calc(100% - 15px) center;
        background-size : 26px 11px;
        option {
          color : $almost-noir;
        }
      }
      > .control-label:after {
        content : " :";
      }
      .control-label {
        font-size     : 15px;
        color         : $almost-noir;
        font-weight   : 400;
        margin-bottom : 15px;
        line-height   : 30px;
        padding-left  : 50px;
      }
    }
    .form-item-submitted-date-de-naissance {
      .webform-container-inline {
        display : inline-block;
        width   : 100%;
      }
      .form-group {
        float         : left;
        width         : 33.3333%;
        padding-right : 20px;
        margin-top    : 0;
        &:last-child {
          padding : 0;
        }
      }
    }
    .form-actions {
      text-align : center;
      margin     : 15px 0 30px;
      .form-submit {
        display        : inline-block;
        padding        : 12px 53px;
        letter-spacing : 1.68px;
        border         : thin solid $almost-noir;
        text-transform : uppercase;
        color          : black;
        font-size      : 14px;
        font-weight    : 400;
        border-radius  : 999px;
        background     : transparent;
        &:hover {
          background      : $rouge;
          color           : $blanc;
          border-color    : transparent;
          text-decoration : none;
        }
      }
    }

    .form-item-submitted-civilite {
      .form-radios {
        display : inline-block;
        width   : 100%;
        .radio {
          float  : left;
          margin : 0 50px 0 0;
        }
      }
    }

    .form-item-submitted-thematiques.form-group,
    .form-item-submitted-les-ouvrages-qui-minterressent,
    .form-item-submitted-univers.form-group,
    .form-item-submitted-nl-general {
      text-align : center;
      > .control-label {
        font-size      : 22px;
        font-weight    : 700;
        text-transform : uppercase;
        width          : 40%;
        position       : relative;
        background     : #ffffff;
        margin-bottom  : 50px;
        @media screen and (max-width : $mobile) {
          width : 80%;
        }
        &:before {
          content    : "";
          position   : absolute;
          top        : 50%;
          left       : -75%;
          height     : 1px;
          background : $gris-moyen;
          width      : 250%;
          z-index    : -1;
          @media screen and (max-width : $mobile) {
            left  : -12.5%;
            width : 125%;
          }
        }
      }
      .form-checkboxes {
        columns    : 2;
        column-gap : 60px;
        width      : 75%;
        margin     : 0 auto;
        text-align : left;
        @media screen and (max-width : $min-mobile) {
          columns : 1;
        }
        &#edit-submitted-univers,
        &#edit-submitted-nl-general {
          columns : 1
        }
        input {
          width       : 24px;
          height      : 24px;
          margin-left : -36px;
        }
        .control-label:after {
          content : "";
        }
      }
    }
    .form-item-submitted-univers.form-group,
    .form-item-submitted-nl-general {
      width : 100%;
    }
    .form-item-submitted-newsletter.form-group {
      .control-label {
        padding-left : 30px;
      }
      input {
        width       : 24px;
        height      : 24px;
        top         : -5px;
        margin-left : -30px;
      }
    }
    .form-type-markup {
      p {
        font-size   : 13px;
        font-weight : 400;
        color       : $gris-sombre;
      }
    }
  }
  #mphone {
    padding-left : 50px;
  }
}

#user-profile-form {
  .form-item-current-pass,
  .form-item-pass {
    display : none;
  }
  .field-group-div {
    max-width : 875px;
    margin    : 0 auto;
  }
  .group-profil {
    display : inline-block;
    .form-wrapper.form-group {
      float   : left;
      width   : 50%;
      padding : 0 25px;
      @media screen and (max-width : $mobile) {
        width : 100%;
      }
    }
  }
  .form-item {
    text-align : left;
  }
  .control-label {
    @extend .TexteCourant;
    color       : $almost-noir;
    font-weight : 400;
  }
  .form-control {
    border-radius : 0;
    border-color  : #9a9999;
    height        : 38px;
    &:active,
    &:focus {
      box-shadow : none;
    }
  }
  .field-name-field-teacher-science,
  .field-name-field-teacher-classes {
    .form-control {
      height : auto;
    }
  }
  .form-textarea {
    height : auto;
  }
  .form-item-mail,
  .form-item-name {
    margin-bottom : 35px;
  }
  .form-item-field-envie-thematique-und,
  .form-item-field-newsletter-type-und {
    text-align    : center;
    margin-bottom : 50px;
    .form-item {
      margin-bottom : 30px;
    }
    > .control-label {
      display : none;
    }
    .form-checkboxes {
      columns    : 3;
      column-gap : 55px;
      display    : inline-block;
      @media screen and (max-width : $mobile) {
        columns : 2;
      }
      @media screen and (max-width : $min-mobile) {
        columns : 1;
      }

      label {
        font-size : 15px;
        color     : $almost-noir;
      }
      input {
        width       : 24px;
        height      : 24px;
        margin-left : -35px;
        top         : -5px;
      }
    }
  }
  .form-item-field-newsletter-type-und {
    .form-checkboxes {
      columns : 1;
    }
    .help-block {
      max-width   : 562px;
      margin      : 50px auto;
      color       : $almost-noir;
      font-size   : 15px;
      line-height : 30px;
    }
  }
  .form-item-field-conditions-und {
    text-align    : center;
    margin-bottom : 25px;
    label {
      font-size : 15px;
      color     : $almost-noir;
    }
    input {
      width       : 24px;
      height      : 24px;
      margin-left : -35px;
      top         : -5px;
    }
  }
  .field-name-field-image {
    input {
      height     : auto;
      border     : none;
      background : none;
      box-shadow : none;
    }
    .input-group-btn {
      button {
        margin    : 0;
        padding   : 13px 50px;
        font-size : 1rem;
      }
    }
    .help-block {
      display : none;
    }
  }
  .form-item-field-social-button-und {
    display : inline-block;
    margin  : 0 auto;
    label {
      display : none;
    }
    .form-item {
      &:first-child {
        //  display : none;
      }
      display       : inline-block;
      float         : left;
      margin-right  : 85px;
      margin-bottom : 0;
      label {
        display : block;
      }
    }
    input {
      display : none;
    }
    .control-label {
      font-size           : 0;
      background-size     : contain;
      background-repeat   : no-repeat;
      background-position : center;
      width               : 55px;
      height              : 51px;
      &[for="edit-field-social-button-und-facebook"] {
        background-image : url('../images/icones/fb.png');
      }
      &[for="edit-field-social-button-und-twitter"] {
        background-image : url('../images/icones/tw.png');
      }
      &[for="edit-field-social-button-und-google"] {
        background-image : url('../images/icones/g.png');
      }
    }
  }

  .form-item-field-journalist-media-und-0 {
    .link-field-title {
      display : none;
    }
    .link-field-url {
      width : 100%;
      input {
        width : 100%;
      }
    }
  }

  .form-submit {
    margin         : 35px 0;
    border-radius  : 999px;
    background     : $rouge;
    color          : #ffffff;
    text-transform : uppercase;
    border         : thin solid $rouge;
    letter-spacing : 1.68px;
    padding        : 13px 125px;
    line-height    : 14px;
    height         : 38px;
    &:hover {
      color      : $rouge;
      background : transparent;
    }
  }

  #edit-cancel {
    position       : absolute;
    background     : transparent;
    border         : none;
    color          : #9a9999;
    font-size      : 10px;
    letter-spacing : 1.2px;
    float          : right;
    &:active {
      box-shadow : none;
    }
    &:after {
      content         : "";
      display         : inline-block;
      width           : 22px;
      height          : 22px;
      background      : url('../images/icones/delete.png') no-repeat;
      background-size : 100%;
      vertical-align  : middle;
      margin-left     : 5px;
      opacity         : 0.77;
    }
    span {
      display : none;
    }
  }
  .has-error {
    input {
      border-color : red;
    }
    label {
      color : red;
    }
  }
}

.field-name-field-gender {
  #user-profile-form & {
    padding : 0 20px;

    .form-radios {
      .control-label {
        display : inline-block;
      }
      .form-item {
        display : inline-block;
        &:last-child {
          padding-left : 50px;
        }
      }
      @media screen and (max-width : $mobile) {
        text-align : center;
      }
    }
  }
}

.field-name-field-nom,
.field-name-field-firstname,
.field-name-field-postal-code,
.field-name-field-city,
.field-name-field-address,
.field-name-field-country,
.field-name-field-mobile-phone {
  #user-profile-form & {
    width         : 50%;
    display       : inline-block;
    margin-bottom : 35px;
    padding       : 0 25px;
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
  }
}

.field-name-field-envie-thematique,
.field-name-field-newsletter-type {
  @media screen and (max-width : $mobile) {
    padding : 0 25px;
  }
}

.field-name-field-address,
.field-name-field-country,
.field-name-field-mobile-phone {
  #user-profile-form & {
    width : 100%;
  }
}

.field-name-field-mobile-phone {
  #user-profile-form & {
    display : none;
  }
}

.field-name-field-birthdate {
  #user-profile-form & {
    display : inline-block;
    width   : 100%;
    padding : 0 25px;
    fieldset {
      border     : none;
      width      : 100%;
      text-align : left;
      box-shadow : none;
    }
    legend {
      border        : none;
      background    : transparent;
      padding       : 0;
      margin-bottom : 5px;
    }
    .panel-body,
    .date-padding {
      padding : 0;
    }
    .form-type-select {
      margin : 0 25px 0 0;
    }
  }
}

.webform-confirmation {
  padding    : 50px 15px;
  text-align : left;
  p {
    color : $almost-noir;

  }
}

#edit-picture {
  background : transparent;
  border     : none;
  width      : 50%;
  text-align : left;
  box-shadow : none;
  padding    : 0 20px;
  float      : left;
  @media screen and (max-width : $mobile) {
    width : 100%;
  }
  legend {
    background : none;
    border     : none;
  }
  .form-control {
    border     : none;
    height     : auto;
    box-shadow : none;
  }
  .user-picture {
    text-align : center;
    background : $gris-clair;
    border     : thin solid #9a9999;
    width      : 222px;
    height     : 222px;
    color      : #9a9999;
    padding    : 15px;
    i {
      font-size   : 72px;
      line-height : 222px;
    }
  }
}

.group-roles {
  #user-profile-form & {
    max-width : 773px;
    margin    : 0 auto;
    label,
    .description {
      color       : $almost-noir;
      font-size   : 15px;
      font-weight : 400;
      font-family : "lft-etica", sans-serif;
    }
    .accordion-item.ui-accordion-header {
      text-align     : left;
      background     : transparent;
      border         : none;
      border-bottom  : thin solid $gris-moyen;
      border-radius  : 0;
      font-family    : "lft-etica", sans-serif;
      padding        : 15px;
      margin-top     : 0;
      pointer-events : none;
      &:before {
        content        : "";
        width          : 24px;
        height         : 24px;
        border         : thin solid #9a9999;
        background     : #ffffff;
        display        : inline-block;
        margin-right   : 15px;
        vertical-align : middle;
        pointer-events : auto;
      }
      &.ui-state-active {
        background : $gris-clair;
      }

      &.checked {
        &:after {
          content        : "";
          width          : 8px;
          height         : 15px;
          position       : absolute;
          border-bottom  : 3px solid $rouge;
          border-right   : 3px solid $rouge;
          left           : 23px;
          top            : 20px;
          @include transform(rotate(45deg));
          pointer-events : auto;
        }
      }
      a {
        color          : $almost-noir;
        font-weight    : 700;
        font-size      : 15px;
        text-transform : uppercase;
        pointer-events : auto;
        &:before {
          content        : 'Je suis ';
          font-weight    : 300;
          text-transform : none;
          pointer-events : auto;
        }
      }
      &.ui-state-active a {
        color : $rouge;
        &:before {
          color : $almost-noir;
        }
      }
      .ui-icon {
        left                : auto;
        right               : 15px;
        width               : 26px;
        height              : 26px;
        top                 : 23px;
        background-image    : url('../images/icones/plus.png');
        background-repeat   : no-repeat !important;
        background-position : center !important;
        pointer-events      : auto;
        &.ui-icon-triangle-1-s {
          background-image : url('../images/icones/close.png');
        }
      }
    }
    .ui-accordion-content {
      border        : none;
      border-radius : 0;
      border-bottom : thin solid $gris-moyen;
      &.ui-accordion-content-active {
        background : $gris-clair;
      }
    }
  }
}

#comment-goodness-confirm-delete {
  margin : 35px 0;
  #edit-actions {
    margin : 15px 0;
    .form-submit {
      margin : 0 20px;
    }
    #edit-cancel {
      color : $almost-noir;
    }
  }
}

#user-login {
  max-width : 740px;
  margin    : 0 auto;
  padding   : 0 15px;
  .form-item {
    text-align : left;
  }
  .control-label {
    font-size     : 15px;
    color         : #1a171b;
    font-weight   : 400;
    margin-bottom : 15px;
  }
  input {
    width         : 100%;
    height        : 58px;
    border-radius : 0;
    border        : thin solid #9a9999;
    padding       : 0 15px;
    &:focus,
    &:active {
      box-shadow : none;
    }
  }
  .form-actions {
    text-align : center;
    margin     : 15px 0 30px;
  }
  button {
    display        : inline-block;
    padding        : 12px 53px;
    letter-spacing : 1.68px;
    border         : thin solid #1a171b;
    text-transform : uppercase;
    color          : black;
    font-size      : 14px;
    font-weight    : 400;
    border-radius  : 999px;
    background     : transparent;
    &:hover {
      background   : $rouge;
      color        : #ffffff;
      border-color : $rouge;
    }
  }
}

.webform-confirmation {
  max-width  : 920px;
  margin     : 0 auto;
  text-align : center;
}

.links {
  .page-node-done & {
    margin-bottom : 50px;
    text-align    : center;
    a {
      color           : $almost-noir;
      text-decoration : underline;
    }
  }
}

.form-item-submitted-optin-sms {
  margin-bottom : 0;
  > label {
    display : none;
  }
  input {
    width  : 16px;
    height : 16px;
  }
}

.form-item-submitted-telephone {
  display : none;
}

#user-cancel-confirm-form {
  margin : 250px 0 50px;
  p {
    margin-bottom : 25px;
  }
  button,
  a {
    margin         : 0 15px;
    padding        : 15px 30px;
    text-transform : uppercase;
    border-radius  : 999px;
    border         : thin solid $rouge;
    color          : #ffffff;
    background     : $rouge;
    vertical-align : middle;
    &:hover {
      background : transparent;
      color      : $rouge;
    }
  }
}

.facetapi-facet-hw-livre-themes ul ul {
  .page-nouveautes &,
  .page-a-paraitre &,
  .page-top-des-ventes &,
  .page-ebooks &,
  .page-adaptation-cinema &,
  .page-prix-litteraires &,
  .field-name-bloc-collection & {
    display : none;
  }
}

#edit-field-social-button-und {
  #user-profile-form & {
    .form-item:first-child {
      display : none;
    }
  }
}

.field-name-field-facebook-url,
.field-name-field-twitter-url,
.field-name-field-google-url {
  .page-user & {
    display : none;
    label {
      display : none;
    }
  }
}

.view-filters {
  .view-id-actualites.view-display-id-page & {
    padding : 45px 0 0;
    .form-item {
      display : block;
      > .form-control {
        border     : none;
        background : transparent;
        box-shadow : none;
      }
      .form-item {
        display      : inline-block;
        margin-right : 30px;
        input {
          width        : 25px;
          height       : 25px;
          float        : left;
          margin-top   : 0;
          margin-right : 5px;
        }
        label {
          display   : inline-block;
          float     : left;
          font-size : 20px;
        }
      }
    }
  }
}

#edit-field-optin-sms {
  padding : 0 25px;
}
