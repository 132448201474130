//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-mobile:             (450px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-xs:                 $container-tablet !default;

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1230px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop;

//
// Grid system
// --------------------------------------------------

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include container-fixed;

  @media (min-width: $screen-xs-min) {
    width: 100%;
  }

  @media (min-width: $screen-sm-min) {
    width: 100%;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @include container-fixed;
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row;
  font-size: 0;

  > div,
  > section,
  > aside {
    font-size: initial;
  }
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns-override;


// Extra extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones on portrait.

@include make-grid-override(xxs);

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@media (min-width: $screen-xs-min) {
  @include make-grid-override(xs);
}


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-grid-override(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-grid-override(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-grid-override(lg);
}

//
// Extra column 2.4 (divide by 5)
//

.col-lg-2-4{
  @include make-lg-column-override(2.4)
}
.col-md-2-4{
  @include make-md-column-override(2.4)
}
.col-sm-2-4{
  @include make-sm-column-override(2.4)
}
