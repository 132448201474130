.block-quicktabs {
  .block-title-wrapper {
    display : none;
  }
  &#block-quicktabs-new-top-paraitre,
  &#block-quicktabs-new-paraitre,
  &#block-quicktabs-new-paraitre-univers {
    @include rem(margin-bottom, 75px);
    padding    : 0 10px;
    text-align : center;
  }
}

.quicktabs-wrapper {
  .quicktabs_main {
    @media screen and (max-width : $mobile) {
      margin : 0 -10px;
    }
    .region-blockgroup-groupe-avis-homepage & {
      margin : 0;
    }
  }
  ul.quicktabs-tabs {
    padding       : 0 0 20px;
    max-width     : 630px;
    margin        : 0 auto 45px;
    border-bottom : thin solid $gris-moyen;
    font-size     : 0;
    text-align    : center;
    .page-user & {
      max-width : 1024px;
      margin    : 0 auto;
    }
    @media screen and (max-width : $mobile) {
      border        : thin solid #8f8f8f;
      padding       : 10px 15px;
      position      : relative;
      display       : inline-block;
      width         : 100%;
      margin-bottom : 15px;
      &:before {
        content  : "";
        @include icone('../images/icones/arrow-down.png', 30px, 15px);
        position : absolute;
        top      : 25px;
        right    : 15px;
        display  : inline-block;
      }
    }
    .region-blockgroup-groupe-avis-homepage & {
      width         : 100%;
      max-width     : none;
      border-bottom : none;
      margin        : 0;
      a {
        color : #ffffff;
      }
      @media screen and (max-width : $mobile) {
        border-color  : #ffffff;
        border-bottom : thin solid #ffffff;
        margin-bottom : 15px;
      }
    }
    li {
      padding     : 0 15px;
      display     : inline-block;
      border-left : thin solid $gris-moyen;
      cursor      : pointer;
      &:first-child {
        border : none;
      }
      &.active a {
        @extend .OngletActif;
      }
      a {
        @extend .OngletInactif;
        color    : $almost-noir;
        display  : inline-block;
        width    : 100%;
        position : relative;
        .#{$univ-polar} & {
          color : $blanc;
        }
      }
      @media screen and (max-width : $mobile) {
        padding    : 10px 0;
        border     : none;
        float      : left;
        clear      : both;
        text-align : center;
        position   : relative;
        width      : 100%;
        display    : none;
        .region-blockgroup-groupe-avis-homepage & {
          text-align : left;
        }
        &.active {
          display : inline-block;
        }
        &.openTab {
          display : inline-block;
        }
      }
      &.open {
        display : inline-block;
      }
    }
  }
}

#quicktabs-profil_quicktab {
  @media screen and (max-width : $mobile) {
    padding : 0 15px;
    .quicktabs_main {
      margin : 0 -10px;

    }
  }
}
