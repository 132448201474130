.pagination {
  border-radius : 0;
  @media screen and (max-width : $mobile) {
    padding : 0 15px;
  }
  li.pager-first a,
  li.pager-last a,
  li.active span {
    border-radius : 50%;
  }
  li span, li a {
    color         : $almost-noir;
    width         : 40px;
    height        : 40px;
    line-height   : 40px;
    font-weight   : 700;
    margin        : 0 1px;
    padding       : 0;
    border-radius : 50%;
    border        : none;
    text-align    : center;
    cursor        : pointer;
    background    : transparent;

    .#{$univ-polar} & {
      background : $blanc;
    }
    &:hover {
      background : $rouge;
      color      : $blanc;
    }
  }
  li.pager-ellipsis.disabled span {
    background : transparent;
  }
  .active span {
    background : $rouge;
    color      : $blanc;
    .#{$univ-polar} & {
      background : $rouge;
    }
    &:hover {
      background : $blanc;
      color      : $rouge;
    }
  }
  /*.page-enseignant & {
    @include bandeau-full-width($gris-clair);
    margin  : 0;
    padding : 20px 0;
    top     : -5px;
    bottom  : -5px;
  }*/
  .comments-list & {
    display    : inline-block;
    margin     : 0;
    width      : 100%;
    text-align : right;
    .next {
      display : inline-block;
      a {
        color  : #ffffff;
        width  : auto;
        height : auto;
        &:hover {
          background : transparent;
        }
        &:after {
          content             : "";
          display             : inline-block;
          width               : 40px;
          height              : 40px;
          border-radius       : 50%;
          margin-left         : 5px;
          border              : thin solid #ffffff;
          background          : url('../images/icones/arrow-down-w.png') no-repeat;
          background-size     : 14px 7px;
          background-position : center;
          vertical-align      : middle;
        }
      }
    }
  }
}

.table-responsive {
  border  : none;
  padding : 0 15px;
}

.pagerer.table-striped {
  tbody tr {
    background : transparent;
  }
  .pager {
    li,
    li a {
      color         : $almost-noir;
      width         : 40px;
      height        : 40px;
      line-height   : 40px;
      font-weight   : 700;
      margin        : 0 1px;
      padding       : 0;
      border-radius : 50%;
      border        : none;
      text-align    : center;
      cursor        : pointer;
      background    : transparent;
      display       : inline-block;
      @media screen and (max-width : $mobile) {
        float : left;
      }
      .#{$univ-polar} & {
        background : $blanc;
      }
    }
    li.pager-current {
      color      : #ffffff;
      background : $rouge;
    }
    li a:hover {
      color      : #ffffff;
      background : $rouge;
    }
  }
}