$bleu-vert :  #9fd6d2;
$turquoise : #4eb3c5;
$rouge : #ce3b3a;
$orange: #f85b5b;
$jaune : #ffe259;
$almost-noir : #1a171b;
$gris-sombre : #797979;
$gris-moyen: #c6c5c5;
$gris-clair: #f1f0f0;
$almost-blanc: #dcdddd;
$blanc : #fff;
$polar: #202021;
$imaginaire: #dfeced;
$romance: #fbe0e7;
$litterature: #fff;
$preludes-500: #098F7D;
$preludes-700: #00594F;
