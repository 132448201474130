.footer {
  background : $almost-noir;
  border     : none;
  font-size  : 0;
  margin     : 0;
  padding    : 40px 0 25px;
  z-index    : 0;
  position   : relative;
  p, a {
    color          : $almost-blanc;
    font-size      : 13px;
    text-transform : uppercase;
    line-height    : 20px;
    margin         : 0;
  }
  p {
    letter-spacing : 2.6px;
  }
  .container {
    width : 100%;
  }
  .block {
    float : left;
    .block-title-wrapper {
      display : none;
    }
  }
}

#block-block-1 {
  img {
    width  : 75px;
    height : auto;
  }
  @media screen and (max-width : $mobile) {
    width         : 100%;
    margin-bottom : 30px;
  }
}

#block-menu-menu-menu-footer {
  @media screen and (max-width : $mobile) {
    padding : 0;
  }
  a {
    line-height : 25px;
    display     : inline-block;
    color       : $almost-blanc;
    padding     : 0;
    &:hover {
      background      : transparent;
      text-decoration : underline;
    }
    &:focus {
      background : transparent;
    }
  }
  li {
    display : inline-block;
    &.active a {
      background : transparent;
    }
  }
  .menu.nav {
    margin-bottom : 25px;
    > .first {
      float   : left;
      padding : 0;
      @extend .col-sm-9;
      @media screen and (max-width : 768px) {
        width : 100%;
      }

      ul {
        columns : 2;
        @media screen and (max-width : $min-mobile) {
          columns : 1;
        }
      }
      li {
        width : 100%;
      }
    }
    > .last {
      float   : left;
      padding : 0;
      @extend .col-sm-3;

      @media screen and (max-width : 768px) {
        width : 100%;
      }
      li {
        width : 100%;
      }
    }
    .dropdown {
      @media screen and (max-width : $mobile) {
        margin-top : 15px;
      }
    }
    .disabled.dropdown-toggle {
      display : none;
    }
    .dropdown-menu {
      display    : block;
      position   : relative;
      background : transparent;
      padding    : 0;
      margin     : 0;
      border     : none;
      box-shadow : none;
    }
  }
}

#block-menu-menu-menu-legal-footer {
  text-align : left;
  float      : right;
  @media screen and (max-width : $mobile) {
    text-align : center;
    padding    : 0;
  }
  .content {
    padding    : 15px 0 0;
    border-top : 2px solid $gris-moyen;
  }
  li, a {
    display        : inline-block;
    padding        : 0;
    text-transform : unset;
  }
  li {
    padding : 10px 0;
    @media screen and (max-width : $mobile) {
      padding : 5px 0;
    }
    &.active a {
      background : transparent;
    }
    a {
      padding     : 0 15px;
      border-left : thin solid $almost-blanc;
      @media screen and (max-width : $mobile) {
        border : none;
      }
      &:hover {
        text-decoration : underline;
      }
      &:focus {
        background : transparent;
      }
    }
    &:first-child a {
      padding-left : 0;
      border       : none;
    }
    &:last-child {
      a {
        &:hover {
          text-decoration : none;
        }
      }
    }
  }
}