.page-univers-preludes {
  /** layout **/
  #page-title-wrapper {
    @include bandeau-full-width($preludes-500);
  }

  .pre-footer {
    background: $preludes-500;
  }

  .footer {
    background: $preludes-700;
  }

  /** pagination **/
  .pagination {
    li span:hover,
    li a:hover {
      background: $preludes-500;
    }
    .active span {
      background: $preludes-500;
      &:hover {
        color: $preludes-500;
      }
    }
  }

  .pagerer.table-striped .pager {
    li.pager-current,
    li a:hover {
      background: $preludes-500;
    }
  }

  /** header **/
  #navbar {
    .navbar-header {
      @media screen and (min-width: $mobile) {
        top: 10px;
        left: calc(50% - 132px);
      }
    }

    &.scrolledNav .navbar-header {
      @media screen and (min-width: $mobile) {
        top: 0;
      }
    }

    .logo img {
      width: 264px;
      height: auto;
      @media screen and (max-width: $mobile) {
        width: 132px;
        margin: 8px 0 0;
      }
    }
  }

  /** menu **/
  .menu.navbar-nav {
    .menu-une,
    .menu-espace,
    .menu-plus,
    .menu-livre {
      li {
        a:hover {
          color: $preludes-500;
        }
        &.active a {
          background: $preludes-500;
        }
      }
    }

    .menu-une,
    .menu-plus {
      max-width: 220px;
      @media screen and (max-width : $mobile) {
        max-width : none;
      }
    }

    .menu-livre {
      max-width: 280px;
      @media screen and (max-width : $mobile) {
        max-width : none;
      }

      ul {
        columns: 1;
      }
    }

    .menu-espace {
      padding: 8px 48px;

      > a {
        line-height: normal;
      }

      > a:hover {
        color: $blanc;
      }
    }
  }

  /** block **/
  .block-social-media-preludes {
    max-width: 792px;
    margin: 50px auto 75px;
    padding: 0 15px;
    @media screen and (max-width : $mobile) {
      margin: 20px auto 45px;
    }

    h4 {
      text-transform: uppercase;
      color: $almost-noir;;
      font-size: 30px;
      font-weight: 300;
    }

    hr {
      margin: 40px 0;
      border-top: 1px solid $almost-noir;
    }

    ul li {
      width: 33%;

      a {
        color: $almost-noir;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .15em;

        .fa {
          display: block;
          margin-bottom: 20px;
        }
      }
    }
  }

  .nl-rs {
    .content {
      display: flex;
      justify-content: center;
      @media screen and (max-width : $mobile) {
        flex-direction: column;
      }
    }

    .newsletter-footer {
      & + .newsletter-footer {
        margin-left: 15px;
        @media screen and (max-width : $mobile) {
          margin-top: 0;
          margin-left: 0;
        }
      }
      &:hover a,
      &:hover .fa {
        color: $preludes-500;
      }
    }
  }

  .region-blockgroup-actuellement {
    background: $preludes-500;
    @include bandeau-full-width($preludes-500);

    .more-link a {
      border-color: $blanc;
      color: $blanc;
      &:hover {
        background: $blanc;
        color: $preludes-500;
      }
    }
  }

  /** node **/
  .node-coup-coeur-libraire {
    &.view-mode-teaser_home,
    &.view-mode-teaser,
    &.view-mode-teaser_livre {
      background: $preludes-700;

      .field-name-node-link::after {
        @include fa-icon();
        content: $fa-var-long-arrow-right;
        width: auto;
        height: auto;
        background: none;
      }

      &:hover .field-name-node-link {
        color: $blanc;
        &::after {
          background-image: none;
        }
      }
    }
  }

  .node-article,
  .node-video {
    &.view-mode-teaser_home,
    &.view-mode-teaser,
    &.view-mode-teaser_livre {
      .field-name-node-link a::after {
        @include fa-icon();
        content: $fa-var-long-arrow-right;
        width: auto;
        height: auto;
        background: none;
      }

      &:hover .field-name-node-link a {
        color: $preludes-500;
        &::after {
          background-image: none;
        }
      }
    }
  }

  /** field **/
  .field-group-div.group-titre-date {
    @include bandeau-full-width($preludes-500);
  }
  &.page-taxonomy-term .field-group-div.group-header-taxo {
    @include bandeau-full-width($preludes-500);
  }

  .node-hw-livre.view-mode-full {
    .field-name-hw-livre-themes,
    .field-name-hw-livre-serie,
    .field-name-hw-livre-collections {
      .field-label {
        color: $preludes-500;
      }
    }
  }

  .acheter {
    background: $preludes-500;
  }

  .field-name-bloc-autopromo {
    display: none;
  }

  /** form **/
  .node-webform.view-mode-full {
    .field-name-title {
      @include bandeau-full-width($preludes-500);
    }

    .webform-client-form .form-actions .form-submit:hover {
      background: $preludes-500;
    }
  }

  /** views **/
  .view {
    &.view-display-id-page_1,
    &.view-display-id-page_2,
    &.view-display-id-page_6 {
      &.view-univers .view-header,
      &.view-video .view-header {
        @include bandeau-full-width($preludes-500);
      }
    }

    .view-header .description-liste {
      @include bandeau-full-width($preludes-500);
    }
  }

  /** home **/
  &.#{$home-preludes} {
    background: $blanc;

    #navbar {
      &.scrolledNav,
      &.whiteBg {
        .logo img.logo-white {
          display: none;
        }
      }

      &:not(.scrolledNav, .whiteBg) {
        .logo img:not(.logo-white) {
          display: none;
        }

        .mail-button {
          border: thin solid #fff;
          a:before {
            background-image: url(../images/icones/iconmessage.png);
            background-size: 55%;
            left: 9px;
            top: 10px;
          }
        }
      }
    }
  }

  /** book **/
  &.node-type-hw-livre #navbar {
    &.scrolledNav {
      .logo img.logo-white {
        display: none;
      }
    }

    &:not(.scrolledNav) {
      background: $preludes-500 !important;

      .logo img:not(.logo-white) {
        display: none;
      }
    }
  }
}
