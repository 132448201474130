body {
  padding-top : 0 !important;
  background  : $blanc;
  overflow-x  : hidden;
  font-family : "lft-etica", sans-serif;
  font-weight : 300;
  &.not-front {
    background : $gris-clair;
  }
  &.menu-mobil-actif {
    overflow : hidden;
  }
  &.page-enseignant,
  &.node-type-webform,
  &.page-node.node-type-hw-livre,
  &.page-user,
  &.node-type-page {
    background : $blanc;
  }
  .main-container {
    @include transition(margin-top, 0.25s);
    &.margin-actif {
      margin-top : 165px;
      @media screen and(max-width : $mobile) {
        margin-top : 100px;
      }
    }
    &.margin-srolled {
      margin-top : 90px;
    }
    &.search-actif {
      margin-top : 395px;
      @media screen and (max-width : $mobile) {
        margin-top : 310px;
      }
      &.margin-srolled {
        margin-top : 255px;
        @media screen and (max-width : $mobile) {
          margin-top : 295px;
        }
      }
    }
  }
  &.front,
  &.#{$univ-imaginaire},
  &.#{$univ-polar},
  &.#{$univ-litterature},
  &.#{$univ-romance},
  &.#{$home-preludes},
  &.page-enseignant {
    .main-container {
      &.margin-actif {
        margin-top : 0;
      }
      &.margin-srolled {
        margin-top : 0;
      }
      &.search-actif {
        margin-top : 0;
        &.margin-srolled {
          margin-top : 0;
        }
      }
    }
  }
}

.ads-region {
  @media screen and (max-width : $mobile) {
    display : none;
  }
}

.main-container {
  .page-coups-de-coeur-des-libraires &,
  .page-selections-thematiques &,
  .page-actualites &,
  .page-videos &,
  .page-agenda &,
  .page-collections &,
  .page-series &,
  .page-le-mag &,
  .page-articles-univers &,
  .page-evenements-univers &,
  .page-coups-de-coeur-des-libraires-univers &,
  .page-videos-univers & {
    max-width : 950px;
  }
}

.content-bottom {
  background : $blanc;
}

a:hover {
  text-decoration : none;
}

#page-title-wrapper {
  text-align : center;
  display    : inline-block;
  position   : relative;
  width      : 100%;
  @include bandeau-full-width($rouge);
  .title {
    color : $blanc;
    @extend .GrosTitreBold;
  }
  .front & {
    display : none;
  }
  .page-enseignant & {
    display : none;
  }
}

.pre-footer {
  background : $rouge;
}

iframe {
  max-width : 100%;
}

.profile {
  .user-header {
    padding       : 25px 15px;
    @include bandeau-full-width($rouge);
    color         : #ffffff;
    margin-bottom : 50px;
    text-align    : center;
    .user-title h1 {
      @include rem(font-size, 30px);
      text-transform : uppercase;
      font-weight    : 700;
    }
    .user-descript {
      font-size   : 20px;
      font-weight : 200;
      b {
        font-weight : 300;
      }
    }
  }
}

.tabs--primary {
  .page-user & {
    display : none;
  }
}


.ads-region {
  .block-title-wrapper {
    display : none;
  }
}

body.front {
  > div.highlighted {
    min-height: 100px;
  }
}
