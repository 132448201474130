.GrosTitreBold{
	font-family: "lft-etica", sans-serif;
	font-size: 30px;
	font-weight: 800;
	text-transform: uppercase;
	@media screen and (max-width: $mobile){
		font-size: 20px;
	}
}

.GrosTitreLight{
	font-family: "lft-etica", sans-serif;
	font-size: 30px;
	font-weight: 200;
	text-transform: uppercase;
	@media screen and (max-width: $mobile){
		font-size: 20px;
	}
}

.TitreRubr{
	font-family: "lft-etica", sans-serif;
	font-size: 18px;
	font-weight: 300;
	text-transform: uppercase;
	@media screen and (max-width: $mobile){
		font-size: 16px;
		font-weight: 200;
	}
}

.TitreArt{
	font-family: "lft-etica", sans-serif;
	font-size: 25px;
	font-weight: 700;
	@media screen and (max-width: $mobile){
		font-size: 18px;
	}
}

.SousTitre{
	font-family: "lft-etica", sans-serif;
	font-size: 15px;
	font-weight: 700;
	@media screen and (max-width: $mobile){
		font-size: 14px;
	}
}

.TexteCourant{
	font-family: "lft-etica", sans-serif;
	font-size: 15px;
	line-height: 20px;
	.view-mode-full &{
		line-height: 25px;
	}
	@media screen and (max-width: $mobile){
		font-size: 14px;
		line-height: 18px;
		.view-mode-full &{
			line-height: 22px;
		}
	}
}

.Mentions{
	font-family: "lft-etica", sans-serif;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	@media screen and (max-width: $mobile){
		font-size: 12px;
	}
}

.FilArrianne{
	font-family: "lft-etica", sans-serif;
	font-size: 13px;
	@media screen and (max-width: $mobile){
		font-size: 12px;
	}
}

.OngletActif{
	font-family: "lft-etica", sans-serif;
	font-size: 22px;
	font-weight: 800;
	@media screen and (max-width: $mobile){
		font-size: 18px;
	}
}

.OngletInactif{
	font-family: "lft-etica", sans-serif;
	font-size: 22px;
	font-weight: 200;
	text-transform: uppercase;
	@media screen and (max-width: $mobile){
		font-size: 18px;
	}
}

.Lien{
	font-family: "lft-etica", sans-serif;
	font-size: 15px;
	font-weight: 300;
	@media screen and (max-width: $mobile){
		font-size: 13px;
	}
}