/*
Box-shadow au hover
*/

.shadow-transition {
  @include transition(box-shadow, 0.25s);
  &:hover {
    box-shadow : 0 0 12px rgba(0, 0, 0, 0.1);
    .field-name-node-link a,
    .field-name-more-link a,
    .field-name-node-link,
    .field-name-more-link {
      color : $rouge;
      &:after {
        background-image : url('../images/icones/arrow-link-hover.png');
      }
    }
  }
}

/*
Responsive video player
*/
.responsive-video {
  .player {
    position       : relative;
    height         : 0;
    width          : 100%;
    padding-bottom : 56.25%;
  }
  iframe {
    position         : absolute;
    left             : 0;
    width            : 100%;
    height           : 100%;
    background-color : black;
  }
}

/*
Fleches lire plus vers le bas
*/
.arrow-down-circle {
  position       : relative;
  display        : inline-block;
  width          : 40px;
  height         : 40px;
  border         : thin solid #1a171b;
  border-radius  : 50%;
  vertical-align : middle;
  .arrow-down {
    width        : 13px;
    height       : 13px;
    border-width : 0 0 thin thin;
    border-color : $almost-noir;
    border-style : solid;
    @include transform(rotate(-45deg));
    position     : absolute;
    top          : 10px;
    left         : 13px;
  }
}

.arrow-down-livre {
  &:after {
    cursor              : pointer;
    content             : "";
    width               : 40px;
    height              : 40px;
    display             : inline-block;
    margin-left         : 5px;
    border-radius       : 50%;
    border              : thin solid $almost-noir;
    background-image    : url('../images/icones/arrow-down-b.png');
    background-size     : 14px 7px;
    background-repeat   : no-repeat;
    background-position : center;
    vertical-align      : middle;
  }
  &:hover {
    &:after {
      border-color     : $rouge;
      background-color : $rouge;
      background-image : url('../images/icones/arrow-down-w.png');
    }
  }
}

/*
Glossaire
 */
.glossaire {
  color         : $almost-noir;
  width         : 42px;
  height        : 42px;
  line-height   : 42px;
  font-weight   : 300;
  font-size     : 18px;
  margin        : 0 1px;
  padding       : 0;
  border-radius : 50%;
  border        : none;
  text-align    : center;
  cursor        : pointer;
  background    : transparent;
  display       : inline-block;
  &.active,
  &:hover {
    background  : $rouge;
    color       : $blanc;
    font-weight : 600;
  }
  @media screen and (max-width : $mobile) {
    width       : 29px;
    height      : 29px;
    line-height : 29px;
    font-size   : 14px;
    font-weight : 600;
  }
}