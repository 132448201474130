.more-link {
  @include cartouche(12, 300);
  .#{$univ-polar} & {
    a {
      color        : #ffffff;
      border-color : #ffffff;
      &:hover {
        border-color : $rouge;
      }
    }
  }
  .view-actualites & {
    @media screen and (min-width : $mobile+1) {
      text-align : center;
    }
  }
  #block-quicktabs-new-top-paraitre &,
  #block-quicktabs-new-paraitre-univers &,
  #block-views-video-block &,
  #block-views-video-block-3 &,
  #block-views-livres-block-13 & {
    @include cartouche(14, 400);
    @media screen and (max-width : $mobile) {
      padding : 0 15px;
    }
  }
  #block-views-video-block &,
  #block-views-video-block-3 & {
    @media screen and (max-width : $mobile) {
      margin-top : 50px;
    }
  }
  .comment.view-mode-full & {
    width   : 100%;
    display : inline-block;
    &:after {
      content             : "";
      font-size           : 0;
      display             : inline-block;
      width               : 40px;
      height              : 40px;
      border-radius       : 50%;
      border              : thin solid $almost-noir;
      background          : url('../images/icones/arrow-down-b.png') no-repeat;
      background-size     : 14px 7px;
      background-position : center;
      cursor              : pointer;
      vertical-align      : middle;
      margin-left         : 5px;
    }
  }

  .comment.view-mode-teaser_user & {
    cursor     : pointer;
    text-align : left;
    &:after {
      content             : "";
      background          : url('../images/icones/arrow-down-b.png') no-repeat;
      width               : 40px;
      height              : 40px;
      display             : inline-block;
      border-radius       : 50%;
      background-position : center;
      border              : thin solid $almost-noir;
      vertical-align      : middle;
      margin-left         : 5px;
    }
  }

  .page-taxonomy-term-2045 & {
    a {
      color  : $blanc;
      border : thin solid $blanc;
    }
  }
}

.view {
  .front &,
  .#{$home-preludes} & {
    .view-header {
      text-align : center;
      p {
        text-transform : uppercase;
        color          : $almost-noir;
        font-size      : 30px;
        margin-bottom  : 40px;
        @media screen and (max-width : $mobile) {
          margin-bottom : 25px;
        }
      }
      strong {
        font-weight : 800;
      }
    }
  }
  .view-empty {
    .header-search {
      br {
        display : none;
      }
    }
  }
  .sous-header-search {
    display : none;
  }
  &.view-display-id-page,
  &.view-display-id-page_1,
  &.view-display-id-page_2,
  &.view-display-id-page_6 {
    .header-search {
      @include bandeau-full-width($rouge);
      color          : $blanc;
      font-size      : 30px;
      text-transform : uppercase;
      padding        : 15px 0 45px;
      text-align     : center;
      @media screen and (max-width : $mobile) {
        font-size : 20px;
        padding   : 15px;
      }
    }
    .view-content {
      padding : 0 15px;
      &.masonry-processed {
        margin : 0 auto;
      }
    }
    &.view-agenda,
    &.view-jeux,
    &.view-actualites,
    &.view-video,
    &.view-selection-thematique,
    &.view-univers {
      .view-header {
        text-align  : center;
        color       : white;
        position    : relative;
        padding     : 0 15px 50px;
        font-size   : 20px;
        font-weight : 200;
        @include bandeau-full-width($rouge);
        &:before {
          top : -5px;
        }
        p {
          color : $blanc;
          @extend .TitreRubr;
        }
        strong {
          @extend .GrosTitreBold;
          line-height : 60px;
        }
      }
    }
  }
  .view-header {
    .description-liste {
      @include bandeau-full-width($rouge);
      .description-liste-inner {
        @include rem(padding-bottom, 40px);
        p {
          color     : $blanc;
          font-size : 20px;
        }
      }
    }
  }

  > .description-liste {
    @include bandeau-full-width($rouge);
    //margin-bottom: 50px;
    .description-liste-inner {
      @include rem(padding-bottom, 40px);
      p {
        color     : $blanc;
        font-size : 20px;
      }
    }
  }

  &.liste-livre {
    .view-header {
      @media screen and (max-width : $mobile) {
        padding : 0 15px 30px;
      }
    }
  }
}

.select-zone {
  @media screen and (max-width : $mobile) {
    display : none;
  }
}

.view-video {
  padding : 0 15px;
  .field-name-bloc-videos-livre & {
    padding : 0;
  }
  .front &,
  .#{$home-preludes} & {
    padding   : 55px 0 25px;
    max-width : 983px;
    margin    : 0 auto;
    @media screen and (max-width : $tab) {
      padding : 15px;
    }
    .view-content {
      display : inline-block;
      float   : right;
      @media screen and (max-width : $tab) {
        float : none;
        width : 100%;
        .views-row {
          display : inline-block;
          margin  : 0 15px;
        }
      }
      @media screen and (max-width : $mobile) {
        display : none;
      }
    }
    .attachment {
      float   : left;
      display : inline-block;
      width   : 668px;
      height  : 406px;
      @extend .responsive-video;
      @media screen and (max-width : $tab) {
        margin : 0 auto 15px;
        float  : none;
        .views-row {
          display : block;
        }
      }
      @media screen and (max-width : $mobile) {
        width  : 100%;
        height : auto;
      }
      .player {
        padding-bottom : 60%;
      }
      .view-content {
        width   : 100%;
        padding : 0;
        @media screen and (max-width : $mobile) {
          display : block;
        }
        .views-row {
          position : relative;
        }
        .views-field-field-video {
          &:before {
            content           : "";
            position          : absolute;
            background-image  : url("../images/icones/play.png");
            width             : 87px;
            height            : 88px;
            background-size   : contain;
            background-repeat : no-repeat;
            z-index           : 1;
            left              : calc(50% - 43px);
            top               : calc(50% - 44px);
            @media screen and (max-width : $mobile) {
              width  : 50px;
              height : 50px;
              left   : calc(50% - 25px);
              top    : calc(50% - 25px);
            }
          }
          &.no-button {
            &:before {
              display: none;
            }
          }
        }
        .views-field-title {
          display          : block;
          padding          : 20px 30px;
          text-align       : left;
          position         : absolute;
          bottom           : 0;
          background-color : rgba(0, 0, 0, 0.5);
          width            : 100%;
          a {
            color : #ffffff;
            @extend .Lien;
          }
        }
      }
      .view-video {
        padding : 0;
      }
      .views-row-2 {
        display : none;
        @media screen and (max-width : $mobile) {
          display : block;
        }
      }
    }
    .more-link {
      float : none;
      clear : both;
    }
  }
  .view-content {
    .page-videos & {
      margin : 50px 0;
    }
  }
}

.view-social-content {
  display : inline-block;
  margin  : 50px 0;
  //float   : left;
  //width   : 25%;
  padding : 0 15px;
  @media screen and (max-width : $mobile) {
    margin : 15px 0;
    width  : 100%;
  }

}

.view-header {
  .view-id-thematique.view-display-id-block_1 & {
    /* padding : 30px;
     @media screen and (max-width : $mobile) {
       padding : 30px 15px;
     }
     .page-taxonomy-term & {
       //@include bandeau-full-width(white);
     }*/
    .#{$univ-imaginaire} &,
    .#{$univ-polar} &,
    .#{$univ-litterature} &,
    .#{$univ-romance} & {
      &:before {
        display : none;
      }
    }
  }
  .view-id-selection_thematique.view-display-id-page &,
  .view-auteur.view-display-id-page &,
  .view-id-video.view-display-id-page_1 &,
  .view-id-coups_de_coeur_libraires.view-display-id-page &,
  .view-id-liste_collection_serie.view-display-id-page_2 &,
  .view-id-liste_collection_serie.view-display-id-page_1 & {
    //background     : $rouge;
    padding-bottom : 50px;
    text-align     : center;
    p {
      color : $blanc;
      @extend .TitreRubr;
    }
    strong {
      @extend .GrosTitreBold;
      line-height : 60px;
    }
    @include bandeau-full-width($rouge);
    &:before {
      top : -5px;
    }
  }

  .filtre-zone {
    @include bandeau-full-width($blanc);
    padding : 30px 0;
    .checkboxes-zone a:before {
      border : thin solid #9a9999;
    }
    @media screen and (max-width : $mobile) {
      padding : 30px 15px;
    }
  }
  /*.page-enseignant & {
    padding-top : 40px;
    border-top  : thin solid $almost-noir;
    @include bandeau-full-width(#ffffff);
    @media screen and (max-width : $mobile) {
      padding : 25px 0;
      margin  : 0 30px;
    }
  }*/
  #block-views-livres-block-13 & {
    border         : none;
    margin-bottom  : 50px;
    @extend .OngletActif;
    text-transform : uppercase;
    color          : $almost-noir;
    display        : inline-block;
    padding        : 0 75px 25px;
    border-bottom  : thin solid $almost-noir;
    text-align     : center;
  }
}

.select-zone {
  //margin-top : 30px;
  .form-item {
    margin : 0;
  }
  .facetapi-select {
    @include appearance(none);
    background : transparent url('../images/icones/arrow-down-b.png') no-repeat 90% center;
    option {
      color : $almost-noir;

      &[selected=selected] {
        display : none;
      }
    }
  }
  @media screen and (max-width : $mobile) {
    .content {
      display : block;
    }
    .block-facetapi {
      width         : 100%;
      margin        : 0;
      margin-bottom : 15px;
      form {
        display : inline-block;
      }
      //label,
      //select{
      //	font-size: 14px;
      //	text-transform: uppercase;
      //	font-weight: 400;
      //	letter-spacing: 1.68px;
      //	color: $almost-noir;
      //}
      .form-item-sort-by {
        text-align : center;
      }
      //select{
      //	border-radius: 999px;
      //	background: transparent;
      //	border-color: $almost-noir;
      //	padding: 0 30px;
      //	height: 40px;
      //	width: 260px;
      //}
    }
  }
}

.selecteur-filtre {
  display : none;
  @media screen and (max-width : $mobile) {
    display        : block;
    width          : 100%;
    border         : 1px solid $almost-noir;
    margin-top     : 25px;
    padding        : 10px 0;
    font-size      : 18px;
    text-transform : uppercase;
    font-weight    : bold;
  }

  &.closed {
    background : transparent url('../images/icones/arrow-down-b.png') no-repeat 90% center;
  }
}

.checkboxes-zone {
  display    : inline-block;
  width      : 100%;
  max-width  : 1072px;
  margin-top : 30px;
  .block-title-wrapper {
    display : none;
  }
  .block-facetapi {
    padding : 0;
    display : inline-block;
  }
  ul {
    font-size : 0;
  }
  li {
    margin-right : 30px;
    font-size    : 15px;
    width        : auto;
  }
  @media screen and (max-width : $mobile) {
    margin-top : 15px;
    text-align : left;
    > .content {
      display : none;
    }
    li {
      margin-bottom : 15px;
    }
  }

  .liste-livre & {
    margin-top : 0;

    > .content {
      padding : 1rem 0;
      border  : 1px solid $gris-sombre;
    }

    .filtrer-par {
      margin         : 0;
      text-align     : left;
      letter-spacing : 0;
      padding        : 1rem 2rem;
      background     : $gris-clair;
      text-transform : uppercase;
      font-size      : 18px;
      color          : $almost-noir;
      .#{$univ-romance} & {
        background : $romance;
      }
      .#{$univ-polar} & {
        background : $polar;
        color      : $blanc;
      }
      .#{$univ-imaginaire} & {
        background : $imaginaire;
      }
      .#{$univ-litterature} & {
        background : $litterature;
      }

      @media screen and (max-width : $mobile) {
        font-weight : 200;
        font-size   : 16px;
      }
    }
    .block-facetapi {
      width   : 100%;
      padding : 0 1rem;
    }
  }
}

.view-filters {
  .view-id-thematique &,
  .liste-livre-univers & {
    margin-top : 45px;
  }
  form {
    display : inline-block;
  }
  label {
    display : none;
  }
  select {
    font-size      : 14px;
    text-transform : uppercase;
    font-weight    : 400;
    letter-spacing : 1.68px;
    color          : $almost-noir;
    @include appearance(none);
  }
  .form-item-sort-by {
    text-align : center;
  }
  select {
    border-radius : 999px;
    background    : transparent url('../images/icones/arrow-down-b.png') no-repeat 90% center;
    border-color  : $almost-noir;
    padding       : 0 50px 0 30px;
    height        : 40px;
    .#{$univ-polar} & {
      border-color : $blanc;
      color        : $blanc;
      background   : transparent url('../images/icones/arrow-down-w.png') no-repeat 90% center;
    }
    &:focus {
      box-shadow : none;
    }
    &:focus {
      border-color : $almost-noir;
      .#{$univ-polar} & {
        border-color : $blanc;
        color        : $blanc;
      }
    }
    option {
      color : $almost-noir;
    }
  }
  .views-submit-button {
    display : none;
  }
}

.view-id-thematique {
  text-align : center;
  .block-view-title {
    p {
      color          : $almost-noir;
      @extend .TitreRubr;
      letter-spacing : 6.3px;
    }
  }

  .view-content {
    margin : 50px 0;
    .views-row {
      vertical-align : top;
    }
    /*.page-enseignant & {
      @include bandeau-full-width($gris-clair);
      margin      : 20px 0 0;
      padding-top : 60px;
    }*/
  }

  /*.#{$univ-romance} &,
  .#{$univ-imaginaire} & {
    .checkboxes-zone a:before {
      border : none;
    }
  }
  .#{$univ-litterature} & {
    .checkboxes-zone a:before {
      background : #eeeeee;
      border     : thin solid #9a9999;
    }
  }
  .#{$univ-polar} & {
    .block-view-title p {
      color : $blanc;
    }
    .select-zone .facetapi-select {
      color        : $blanc;
      border-color : $blanc;
    }
    .checkboxes-zone .facetapi-checkbox,
    .checkboxes-zone li {
      color : $blanc;
    }
    .view-filters label,
    .view-filters select {
      color        : $blanc;
      border-color : $blanc;
    }
  }
  /*.view-header {
    .page-prix-litteraires &,
    .page-adaptation-cinema &,
    .page-ebooks & {
      padding : 50px 15px;
      @include bandeau-full-width(#ffffff);
    }
  }*/
}

.view-multi-index-search.view-display-id-page {
  .facet-checkbox-wrapper {
    display : inline-block;
    padding : 30px 15px;
    @include bandeau-full-width($blanc);
    width   : 100%;
    @media screen and (max-width : $mobile) {
      text-align : center;
    }
  }
  .desktop-title {
    text-align     : center;
    padding-bottom : 30px;
    color          : $almost-noir;
    @extend .TitreRubr;
    letter-spacing : 6.3px;
    @media screen and (max-width : $mobile) {
      display : none;
    }
  }
  .mobile-title {
    display        : none;
    text-align     : center;
    @extend .TitreRubr;
    font-size      : 14px;
    letter-spacing : 1.56px;
    @include rem(padding, 14px 75px);
    border-radius  : 999px;
    border         : thin solid $almost-noir;
    line-height    : 14px;
    cursor         : pointer;
    margin         : 0 auto;
    @media screen and (max-width : $mobile) {
      display : inline-block;
    }
  }
  .view-content {
    padding : 45px 0;
    @media screen and (max-width : $mobile) {
      padding : 15px;
    }
  }
}

.masonry-item {
  .view-auteur.view-display-id-page & {
    border     : none;
    background : transparent;
    padding    : 0;
    margin     : 15px;
    width      : 260px;
  }
}

.view-liste-collection-serie,
.view-auteur.view-display-id-page {
  .view-display-id-attachment_1 {
    padding : 45px 0;
    @include bandeau-full-width(#ffffff);
    @media screen and (max-width : $mobile) {
      padding : 30px 0;
    }
    .views-summary a {
      @extend .glossaire;
    }
  }
  .view-content {
    @media screen and (max-width : $mobile) {
      padding : 0 15px;
    }
  }
}

.pager-show-more {
  .field-name-bloc-agenda-livre &,
  .field-name-bloc-actualites-livre &,
  .field-name-bloc-agenda-auteur &,
  .field-name-bloc-actualites-auteur & {
    text-align : right;
  }
  .field-name-bloc-videos-livre & {
    text-align : center;
  }
  .node-hw-livre.view-mode-full &,
  .node-hw-intervenant.view-mode-full & {
    margin-bottom : 30px;
    .pager-show-more-next,
    .pager-show-more-next a {
      cursor         : pointer;
      display        : inline-block;
      color          : $almost-noir;
      font-size      : 14px;
      font-weight    : 400;
      background     : transparent;
      border         : none;
      vertical-align : middle;
      padding        : 0;
    }
    .pager-show-more-next {
      &:hover {
        color : $rouge;
        a {
          color : $rouge;
        }
      }
    }
    .pager-show-more-next {
      a {
        @extend .arrow-down-livre;
      }
    }
    &.pager-show-more-empty {
      .pager-show-more-next {
        a {
          @extend .arrow-down-livre;
          &:after {
            background-image : url('../images/icones/arrow-up-b.png');
          }
          &:hover {
            &:after {
              background-image : url('../images/icones/arrow-up-w.png');
            }
          }
        }
      }
    }
  }
}

.view-avis {
  #block-quicktabs-avis-homepage & {
    background : #ffffff;
    padding    : 40px;
    display    : inline-block;
    width      : 100%;
    @media screen and (max-width : $mobile) {
      padding : 15px;
    }
  }
}

.view-livres.view-display-id-block_13 {
  @media screen and (max-width : $mobile) {
    text-align : center;
  }
}

.view-livres.liste-livre {
  .page-livre-recommande & {
    margin-top : 50px;
  }
}

.view-livres.view-display-id-page {
  @include rem(padding-top, 45px);
}

.view-alertes {
  > .view-content {
    padding : 0 15px;
    > .views-row {
      padding : 20px 0;
      @media screen and (min-width : $mobile) {
        &.views-row-first {
          padding : 0 0 20px;
        }
      }
      .views-field {
        @media screen and (max-width : $mobile) {
          width : 100%;
          &.views-field-ops {
            position : absolute;
            top      : 0;
          }
        }
      }
      &:after {
        content    : "";
        position   : absolute;
        right      : 0;
        left       : 0;
        height     : 1px;
        background : $gris-moyen;
        bottom     : 0;
        text-align : center;
        margin     : 0 auto;
        @media screen and (max-width : $mobile) {
          height : 3px;
          width  : 100%;
        }
      }
    }
  }
}

.view-dossiers-presse {
  > .view-header {
    a, p {
      @include rem(font-size, 22px);
      line-height : 24px;
      font-weight : 300;
      color       : $almost-noir;
    }
    p {
      padding : 0 15px;
      @media screen and (max-width : $mobile) {
        color : $gris-sombre;
      }
    }
    a {
      text-decoration : underline;
    }
  }
  > .view-content {
    > .views-row {
      padding : 16px 0;
      @media screen and (min-width : $mobile) {
        &.views-row-first {
          padding : 0 0 16px;
        }
      }
      > .node-dossier-presse.node-teaser {
        &:hover {
          box-shadow : 0 0 0 0 !important;
        }
        > .row > .col-sm-12 {
          text-align : left;
          font-size  : 0;
          div, span {
            display        : inline-block;
            vertical-align : middle;
            line-height    : 24px;
            font-size      : 22px;
            &.field-group-div.group-titre-date {
              width          : auto;
              padding-bottom : 0;
              margin-bottom  : 0;
              position       : static;
              text-align     : left;
              &:before {
                display : none;
              }
              @media screen and (max-width : $mobile) {
                width : 80%;
              }
            }
            &.field-name-title-field {
              margin-right : 10px;
              h2 {
                padding : 0;
                margin  : 0;
                display : inline-block;

                @media screen and (max-width : $mobile) {
                  display      : inline;
                  font-size    : 22px;
                  line-height  : 30px;
                  margin-right : 4%;
                }
                a {
                  border-bottom : 1px solid $almost-noir;
                }
              }
            }
            &.field-name-post-date {
              margin-bottom : 0;
              .field-item {
                font-weight : 300;
                line-height : 30px;
                margin-left : 4%;
                @media screen and (max-width : $mobile) {
                  color       : $gris-sombre;
                  margin-left : 0;
                }
              }
            }

            &.download-communique-presse {
              margin-right : 4%;

              a {
                text-indent : -9999px;
                background  : url(../images/icones/download50.png) no-repeat center;
                width       : 48px;
                height      : 48px;
                display     : inline-block;
                text-align  : middle;
              }
            }
          }
        }
      }
    }
  }
}

.view-teasers-profil {
  .view-header {
    max-width : 875px;
    margin    : 25px auto 0;
    .group-alertes {
      @media screen and (max-width : $mobile) {
        display : none;
      }
    }
  }
  .view-content {
    max-width  : 960px;
    margin     : 65px auto 75px;
    text-align : left;
    .views-row {
      font-size : 0;
      position  : relative;
      margin    : 0 auto;
      width     : 100%;
      max-width : 875px;
      .field,
      .views-field,
      .download-communique-presse {
        display        : inline-block;
        vertical-align : middle;
        &.views-field-title-field {
          padding-right : 5px;
          font-weight   : 700;
          @media screen and (max-width : $mobile) {
            padding-bottom : 6px;
          }
        }
        .field-item,
        .field-item a,
        .field-content,
        .field-content a {
          font-size   : 22px;
          line-height : 24px;
          color       : $almost-noir;
        }

        &.views-field-nothing .field-content {
          font-size : 20px;
          .separator {
            font-size : 22px;
          }
          @media screen and (max-width : $mobile) {
            font-size : 14px;
            .separator {
              display : none;
            }
          }
        }
      }
    }
  }
}

#quicktabs-profil_quicktab {
  .view-empty {
    max-width      : 875px;
    margin         : 0 auto 30px;
    padding-bottom : 15px;
    position       : relative;
    p {
      font-size : 22px;
      @media screen and (max-width : $mobile) {
        color : $gris-moyen;
      }
    }
    &:after {
      content    : "";
      width      : 82%;
      position   : absolute;
      right      : 0;
      left       : 0;
      height     : 1px;
      background : $gris-moyen;
      bottom     : 0;
      text-align : center;
      margin     : 0 auto;
    }
  }
}

.liste-livre {
  //background : $blanc;
  .#{$univ-romance} & {
    background : $romance;
  }
  .#{$univ-polar} & {
    background : $polar;
  }
  .#{$univ-imaginaire} & {
    background : $imaginaire;
  }
  .#{$univ-litterature} & {
    background : $litterature;
  }

  &:not(.liste-livre-univers) .view-header {
    width          : 25%;
    display        : inline-block;
    vertical-align : top;
    background     : $blanc;
    @media screen and (max-width : $mobile) {
      width : 100%;
    }
  }
  .view-wrapper {
    background : $gris-clair;
    width      : 75%;
    display    : inline-block;
    .#{$univ-romance} & {
      background : $romance;
    }
    .#{$univ-polar} & {
      background : $polar;
    }
    .#{$univ-imaginaire} & {
      background : $imaginaire;
    }
    .#{$univ-litterature} & {
      background : $litterature;
    }

    @media screen and (max-width : $mobile) {
      width : 100%;
    }

    .view-filters {
      font-size : initial;
    }

    .view-content {
      font-size : 0;
      margin    : 50px 0 0;
      padding   : 0 30px;
      @media screen and (max-width : $mobile) {
        padding : 0;
      }
    }
  }
}

.view-coups-de-coeur-libraires {
  .node-hw-livre.view-mode-full &,
  .node-hw-intervenant.view-mode-full & {
    .slick {
      padding    : 35px 25px;
      text-align : left;
      color      : #ffffff;
      font-size  : 15px;
      span,
      p,
      a {
        color : #ffffff;
      }
      a:hover {
        color : $rouge;
      }

      .views-field-title {
        margin-bottom : 15px;
        span {
          font-size   : 25px;
          font-weight : 700;
        }
      }
      .views-field-field-libraire {
        margin-top : 30px;
      }
    }
  }
}

.view-pal.view-display-id-block_1 {
  margin-top : 50px;
  @media screen and (max-width : $mobile) {
    .view-content {
      padding : 0 15px;
    }
    .views-row {
      width          : 100%;
      padding        : 0;
      padding-bottom : 25px;
      border-bottom  : thin solid $almost-blanc;
    }
    .field-name-social-share,
    .field-name-title-field,
    .field-name-hw-intervenants,
    .field-name-hw-livre-date-parution,
    .field-lien-post-avis {
      float        : left;
      width        : 50%;
      text-align   : left;
      padding-left : 5px;
    }
    .field-lien-post-avis a {
      font-size : 12px;
      padding   : 10px 15px;
    }
  }
}

.profile {
  > .user-picture {
    display : none;
  }
}

.view-commentaires {
  .page-user & {
    padding : 0 15px;
  }
}

#site-map {
  text-align : left;
  max-width  : 790px;
  margin     : 0 auto;
  .title {
    display : none;
  }
  .content {
    margin : 45px 0;
    > ul > li {
      margin-bottom : 15px;
      > a {
        text-transform : uppercase;
      }
    }
    a {
      color         : $almost-noir;
      display       : inline-block;
      margin-bottom : 5px;
      &:hover {
        text-decoration : unederline;
      }
    }
  }
}

.view-empty {
  .view-id-jeux &,
  .view-id-agenda & {
    margin         : 45px 0;
    text-align     : center;
    font-size      : 24px;
    font-weight    : 500;
    text-transform : uppercase;
  }
}

.view-agenda .view-footer .view-agenda {
  .view-header {
    color          : $almost-noir;
    font-weight    : 700;
    font-size      : 24px;
    text-transform : uppercase;
    text-align     : left;
    padding-bottom : 0;
    &:before {
      display : none;
    }
  }
}

.live-result-search-item-wrapper {
  a {
    color: $almost-noir;
    padding: 2px 0;
    display: inline-block;

    &:hover {
      color: $rouge;
    }
  }
}

.view-univers.view-display-id-block_3,
.view-univers.view-display-id-block_4 {
  .view-content {
    display: flex;
    justify-content: center;
  }

  .views-row {
    padding: 0 10px;
  }

  .group-couv-picto.field-group-div {
    display: block;
    width: auto;
  }
}
