.size-zero {
  font-size: 0;

  > div {
    font-size: initial;
  }

  > .view-content {
    font-size: 0;
    text-align: center;

    > div {
      font-size: initial;
      vertical-align: top;
    }
  }
}

.liste-livre {
  .views-row {
    @include rem(margin-bottom, 50px);
  }
}
