.menu.navbar-nav {
  width      : 100%;
  text-align : center;
  @media screen and (max-width : $mobile) {
    margin : 0;
  }
  .dropdown-menu {
    display    : block;
    border     : none;
    box-shadow : none;
    width      : 100%;
    position   : relative;
    background : transparent;
    font-size  : 0;
  }
  .menu-une, .menu-espace, .menu-plus, .menu-livre {
    text-align  : left;
    margin-left : 60px;
    width       : 100%;
    display     : inline-block;
    li a,
    > a {
      padding     : 0;
      line-height : 30px;
      font-size   : 15px;
      color       : $almost-noir;
      font-weight : 300;
    }
    a:hover {
      background : transparent;
    }
    li {
      a:hover {
        color       : $rouge;
        font-weight : 400;
      }
      &.active {
        margin : 0 -10px;
        a {
          background : $rouge;
          padding    : 0 10px;
          color      : $blanc;
        }
      }
    }
    .menu-parent {
      @extend .TitreRubr;
      line-height    : 50px;
      position       : relative;
      letter-spacing : 3.56px;
      &.active-trail {
        background : transparent;
      }
    }
    @media screen and (max-width : $mobile) {
      .dropdown-menu {
        display : inline-block;
      }
      margin-left : 0;
      margin-top  : 10px;
      &:first-child {
        margin-top : 0;
      }
      li {
        border-top : 1px solid #eceded;
        &:last-child {
          border-bottom : 1px solid #eceded;
        }
      }
      li a {
        margin-left : 15px;
      }
      > a {
        margin-left : 15px;
      }
    }
  }
  .menu-une {
    max-width : 165px;
    @media screen and (max-width : $mobile) {
      max-width : none;
    }
  }
  .menu-livre {
    max-width : 385px;
    ul {
      columns : 2;
      @media screen and (max-width : $mobile) {
        columns : 1;
      }
    }
    li {
      display : inline-block;
      width   : 100%;
    }
    @media screen and (max-width : $mobile) {
      max-width : none;
    }
  }
  .menu-plus {
    max-width : 170px;
    @media screen and (max-width : 929px) {
      clear      : both;
      margin-top : 10px;
    }
    @media screen and (max-width : $mobile) {
      max-width : none;
    }
  }
  .menu-espace {
    background     : $rouge;
    text-align     : center;
    max-width      : 244px;
    padding-bottom : 0;
    li {
      text-align : center;
    }
    li a,
    > a {
      text-transform : uppercase;
      color          : $blanc;
      display        : inline-block;
      margin         : 0;
      font-weight    : 200;

      &:hover {
        color : $rouge;
      }
    }
    li {
      &:hover {
        padding : 0 15px;
        a {
          width      : 100%;
          background : $blanc;
        }
      }
    }
    .espace-raisons {
      margin-top : 15px;
      a {
        text-transform : unset;
      }
    }
    .menu-parent {
      font-weight : 200;
      &:hover {
        color : $blanc;
      }
    }
    .espace-perso-infos {
      .logged-in & {
        display : none;
      }
    }
    .espace-user-link {
      display : none;
      .logged-in & {
        display : inline-block;
      }
    }

    ul {
      padding-bottom: 0;
    }

    .menu-preludes {
      background: #098f7d;
      padding: 10px;
      margin-top: 20px;
      border-top: 20px solid white;

      a {
        font-weight: bold;

        &:hover {
          color: white;
          font-weight: bold;
        }
      }

      &:hover {
        padding: 10px;
        background: #098f7d;

        a {
          width: auto;
          background: #098f7d;
        }
      }
    }

    @media screen and (max-width : $desk-inter) {
      clear      : both;
      margin-top : 10px;
    }
    @media screen and (max-width : 929px) {
      clear : none;
    }
    @media screen and (max-width : $mobile) {
      .dropdown-menu {
        margin : 0 auto;
      }
      li {
        border : none;
        &:last-child {
          border : none;
        }
      }
      > a {
        margin-left : 0;
      }
      .menu-preludes {
        border-top: 20px solid white !important;
      }
    }
  }
  .menu-une, .menu-plus, .menu-livre {
    > a {
      &:after {
        content    : "";
        position   : absolute;
        bottom     : 0;
        left       : 0;
        width      : 25px;
        height     : 3px;
        background : #000000;
      }
      margin-bottom : 10px;
    }
  }
  .menu-une, .menu-plus, .menu-livre, .menu-espace {
    a:focus {
      background : transparent;
    }
  }

}
